import React, { FC } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WTAButton.scss';
import { i18n } from "../../../../../symphony";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { WebToAppStore } from "../../../stores/webtoapp.store";

type Props = {
	onClick: () => void;
	background?: 'purple' | 'white';
	locKey?: string;
	disabled?: boolean;
	loading?: boolean;
	className?: string;
	children?: React.ReactNode;
};

export const WTAButton: FC<Props> = ({
	onClick,
	disabled,
	className,
	background = 'purple',
	locKey = 'continue',
	loading = false,
	children = null

}) => {
	useStyles(s);
	const { footerButtonStyles } = useContainerData('wta', WebToAppStore, ['footerButtonStyles']);

	return <button
		disabled={disabled}
		className={`wta-button ${background} ${loading ? 'loading' : ''} ${className || ''}`}
		onClick={onClick}
		style={footerButtonStyles}
	>
		{children ? children : i18n.t(locKey)}
	</button>;
};
