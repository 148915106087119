import { WTAJourney, WTAPage, WTAPageType, WTAPlanBuildingStep } from "./types/webtoapp.types";

export const WTAGettingStartedPage: WTAPage = {
	id: 239,
	type: WTAPageType.getStarted,
	title: "wta.page.239.title",
	content: [],
	style: {},
	pageOptions: []
};

export const WTAInitializationPage: WTAPage = {
	id: 0,
	type: WTAPageType.flowInitialization,
	title: '',
	content: [],
	style: {}
};

export const WTAPlanBuildingSteps: WTAPlanBuildingStep[] = [
	{
		title: 'Milestones',
		status: 'notStarted',
		question: 'Ready to see how learning can transform your career in days?',
		reviewer: 'Goriotoo',
		review: 'This app is great for learning. It progresses perfectly, in a very organized manner, making it enjoyable and fun.',
		progress: 0
	},
	{
		title: 'Goals',
		status: 'notStarted',
		question: 'Do you often set goals for yourself?',
		review: 'Great step-by-step AI course. This course is really good. Great structure and easy to follow. Pleasantly surprised. Thank you.',
		reviewer: 'Renée L',
		progress: 0
	},
	{
		title: 'Content',
		status: 'notStarted',
		question: 'Do you tend to finish what you start?',
		review: 'WE CAN PUT IN OUR OWN PROMPTS!!! 🥳🥳🥳🥳🥳🥳',
		reviewer: 'Finn Delahunty',
		progress: 0
	},
	{
		title: 'Challenges',
		status: 'notStarted',
		question: 'Feel good when you achieve daily goals?',
		review: `Very educative and honestly I'm really impressed with the content provided in a clear and precise manner`,
		reviewer: 'kauta zimba',
		progress: 0
	},
	{
		title: 'Difficulty adjustments',
		status: 'notStarted',
		question: 'Do you prefer learning with a group?',
		review: 'This course is awesome - this is the perfect format to teach practical AI skills. We love it.',
		reviewer: 'Tomás Dostal-Freire, Miro',
		progress: 0
	},
];

export const WTADefaultJourney: WTAJourney = ({
	"pages": [
		{
			"id": 368,
			"type": "getStarted",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "wta.page.239.title",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "sololearnLogo",
					"data": ""
				},
				{
					"type": "h1",
					"data": "Master AI and Embrace Flexibility",
					"style": {
						"marginBottom": "12px"
					}
				},
				{
					"type": "paragraph",
					"data": "Gain the skills to work from anywhere with Sololearn’s AI courses",
					"style": {
						"marginBottom": "24px"
					}
				},
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/workingfromanywhere.svg",
					"style": {
						"marginBottom": "48px"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 307,
			"type": "singleChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "wta.page.239.title",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "How much do you think using AI could increase your income?"
				},
				{
					"type": "singleChoiceList",
					"data": null
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": [
				{
					"id": 273,
					"pageId": 307,
					"label": "📈 15%",
					"preselected": false,
					"value": 273,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 2008,
					"answerId": 2008,
					"iconUrl": ""
				},
				{
					"id": 274,
					"pageId": 307,
					"label": " 🚀 39%",
					"preselected": false,
					"value": 274,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 2009,
					"answerId": 2009,
					"iconUrl": ""
				},
				{
					"id": 275,
					"pageId": 307,
					"label": "❌ No increase",
					"preselected": false,
					"value": 275,
					"navigation": 2,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 2010,
					"answerId": 2010,
					"iconUrl": ""
				}
			]
		},
		{
			"id": 308,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "wta.page.239.title",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/excitingnews.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Bingo!",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "Employers are willing to pay up to 39% more for workers with AI skills.",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 309,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "wta.page.239.title",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/statisticchart.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Not quite.",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "Employers are willing to pay up to 39% more for workers with AI skills.",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 310,
			"type": "singleChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "How much time do you think the average office worker could save per week by using the latest AI tools?"
				},
				{
					"type": "singleChoiceList",
					"data": null
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": [
				{
					"id": 278,
					"pageId": 310,
					"label": "⏳ 25%",
					"preselected": false,
					"value": 278,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 2013,
					"answerId": 2013,
					"iconUrl": ""
				},
				{
					"id": 277,
					"pageId": 310,
					"label": "⏱️ 10%",
					"preselected": false,
					"value": 277,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 2012,
					"answerId": 2012,
					"iconUrl": ""
				},
				{
					"id": 276,
					"pageId": 310,
					"label": "🐢 Very little",
					"preselected": false,
					"value": 276,
					"navigation": 2,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 2011,
					"answerId": 2011,
					"iconUrl": ""
				}
			]
		},
		{
			"id": 311,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/target.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Bingo!",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "You can save up to 10 hours a week by automating repetitive tasks, and this number is only going to grow. The best part? You can use that extra time for more creative and strategic work. It’s a win-win!",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 312,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/innotime.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Not quite.",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "You can save up to 10 hours a week by automating repetitive tasks, and this number is only going to grow. The best part? You can use that extra time for more creative and strategic work. It’s a win-win!",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 313,
			"type": "singleChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "How easy do you think it is to start using AI tools to improve your daily tasks?"
				},
				{
					"type": "singleChoiceList",
					"data": null
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": [
				{
					"id": 279,
					"pageId": 313,
					"label": "🌟 Super easy",
					"preselected": false,
					"value": 279,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 2014,
					"answerId": 2014,
					"iconUrl": ""
				},
				{
					"id": 280,
					"pageId": 313,
					"label": "🌿 Somewhat easy",
					"preselected": false,
					"value": 280,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 2015,
					"answerId": 2015,
					"iconUrl": ""
				},
				{
					"id": 281,
					"pageId": 313,
					"label": "🧩 A bit challenging",
					"preselected": false,
					"value": 281,
					"navigation": 2,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 2016,
					"answerId": 2016,
					"iconUrl": ""
				}
			]
		},
		{
			"id": 316,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/adventuremap.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "No problem!",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "AI may seem complex, but it’s easier than you think. Let’s take it one step at a time and show you how it can improve your productivity!",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 315,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/completedsteps.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "No worries!",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "We’re here to guide you step by step. AI tools are designed to make your life easier, and you’ll see how simple it can be to get started!",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 314,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/joyride.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Exactly!",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "It’s super easy to get started with AI! With just a few clicks a day, you'll see how AI can boost your productivity even more!",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 317,
			"type": "singleChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "Would you be willing to invest just a few minutes a day to boost your productivity? 5 minutes is all it takes!"
				},
				{
					"type": "singleChoiceList",
					"data": null
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": [
				{
					"id": 282,
					"pageId": 317,
					"label": "✅ Yes",
					"preselected": false,
					"value": 282,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 2017,
					"answerId": 2017,
					"iconUrl": ""
				},
				{
					"id": 283,
					"pageId": 317,
					"label": "🙅‍♂️ No",
					"preselected": false,
					"value": 283,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 2018,
					"answerId": 2018,
					"iconUrl": ""
				},
				{
					"id": 284,
					"pageId": 317,
					"label": "ℹ️ Tell me more",
					"preselected": false,
					"value": 284,
					"navigation": 2,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 2019,
					"answerId": 2019,
					"iconUrl": ""
				}
			]
		},
		{
			"id": 318,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/choose.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "That’s the spirit!",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "It’s amazing what just 5 minutes a day can do. You’re going to see results faster than you think!",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 319,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/taken.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Come on, don’t give up so easily!",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "Let us show you how you can start making more money and becoming more productive in no time.",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 320,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/natureonscreen.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Sure thing!",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "With just 5 minutes a day, you’ll learn easy tricks to automate tasks, save time, and focus on what really matters.",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 321,
			"type": "credibility",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				},
				"version": "featuredIn"
			},
			"pageOptions": []
		},
		{
			"id": 322,
			"type": "singleChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "What is your age?"
				},
				{
					"type": "singleChoiceList",
					"data": null
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": [
				{
					"id": 256,
					"pageId": 322,
					"label": "wta.age.under-18",
					"preselected": false,
					"value": 1,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 6,
					"answerTypeId": 88,
					"answerId": 88,
					"iconUrl": null
				},
				{
					"id": 257,
					"pageId": 322,
					"label": "wta.age.19-24",
					"preselected": false,
					"value": 2,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 6,
					"answerTypeId": 18,
					"answerId": 18,
					"iconUrl": null
				},
				{
					"id": 258,
					"pageId": 322,
					"label": "wta.age.25-34",
					"preselected": false,
					"value": 3,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 6,
					"answerTypeId": 19,
					"answerId": 19,
					"iconUrl": null
				},
				{
					"id": 259,
					"pageId": 322,
					"label": "wta.age.35-44",
					"preselected": false,
					"value": 4,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 6,
					"answerTypeId": 20,
					"answerId": 20,
					"iconUrl": null
				},
				{
					"id": 260,
					"pageId": 322,
					"label": "wta.age.45-over",
					"preselected": false,
					"value": 5,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 6,
					"answerTypeId": 21,
					"answerId": 21,
					"iconUrl": null
				}
			]
		},
		{
			"id": 323,
			"type": "singleChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "What is your gender?"
				},
				{
					"type": "singleChoiceList",
					"data": null
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": [
				{
					"id": 285,
					"pageId": 323,
					"label": "🙋‍♂️ Male",
					"preselected": false,
					"value": 285,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 2020,
					"answerId": 2020,
					"iconUrl": ""
				},
				{
					"id": 286,
					"pageId": 323,
					"label": "🙋‍♀️ Female",
					"preselected": false,
					"value": 286,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 2021,
					"answerId": 2021,
					"iconUrl": ""
				},
				{
					"id": 287,
					"pageId": 323,
					"label": "⚧️ Other",
					"preselected": false,
					"value": 287,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 2022,
					"answerId": 2022,
					"iconUrl": ""
				}
			]
		},
		{
			"id": 324,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/welcoming.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Hey, nice to meet you!",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "We’ll help boost your productivity and earn more money. First, let’s dive into your personality and tailor your personal plan.",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 326,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/timemanagement.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Understand and apply concepts in minutes instead of hours.",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "Practice using real-life tools and examples for just 5 minutes a day. This hands-on approach ensures you’ll quickly improve and put your new knowledge into action in real-world scenarios.",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 327,
			"type": "singleChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "Are you worried about AI changing the future of your job?"
				},
				{
					"type": "singleChoiceList",
					"data": null
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": [
				{
					"id": 282,
					"pageId": 327,
					"label": "✅ Yes",
					"preselected": false,
					"value": 282,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 2017,
					"answerId": 2017,
					"iconUrl": ""
				},
				{
					"id": 283,
					"pageId": 327,
					"label": "🙅‍♂️ No",
					"preselected": false,
					"value": 283,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 2018,
					"answerId": 2018,
					"iconUrl": ""
				}
			]
		},
		{
			"id": 328,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/serverdown.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "It’s totally normal to feel that way.",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "The good news is, you’re already taking steps to adapt and grow. You’ve got what it takes to stay ahead!",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 329,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/throughthedesert.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "That’s great!",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "You’ve got the right mindset to embrace the future. Keep building on that confidence, and you’ll be ready for anything!",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 330,
			"type": "singleChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "Do you consider yourself an early adopter?"
				},
				{
					"type": "singleChoiceList",
					"data": null
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": [
				{
					"id": 282,
					"pageId": 330,
					"label": "✅ Yes",
					"preselected": false,
					"value": 282,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 2017,
					"answerId": 2017,
					"iconUrl": ""
				},
				{
					"id": 283,
					"pageId": 330,
					"label": "🙅‍♂️ No",
					"preselected": false,
					"value": 283,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 2018,
					"answerId": 2018,
					"iconUrl": ""
				}
			]
		},
		{
			"id": 331,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/sharedworkspace.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Awesome!",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "You'll fit right in with our innovative learners. Let’s dive into the latest tech together!",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 332,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/creativethinking.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "No worries.",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "Plenty of learners here prefer a steady approach to tech. You’re joining a group that values learning at their own pace.",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 333,
			"type": "singleChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "Do you consider yourself tech-savvy?"
				},
				{
					"type": "singleChoiceList",
					"data": null
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": [
				{
					"id": 282,
					"pageId": 333,
					"label": "✅ Yes",
					"preselected": false,
					"value": 282,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 2017,
					"answerId": 2017,
					"iconUrl": ""
				},
				{
					"id": 283,
					"pageId": 333,
					"label": "🙅‍♂️ No",
					"preselected": false,
					"value": 283,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 2018,
					"answerId": 2018,
					"iconUrl": ""
				}
			]
		},
		{
			"id": 334,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/freelancer.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Awesome!",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "Your tech know-how puts you in good company here. Let’s take your skills to the next level together!",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 335,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/differentlove.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "That’s perfectly okay!",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "A lot of our learners are just getting started, and we’re here to support you on your tech journey.",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 336,
			"type": "singleChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "How often do you use AI tools?"
				},
				{
					"type": "singleChoiceList",
					"data": null
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": [
				{
					"id": 398,
					"pageId": 336,
					"label": "🙅‍♀️ Never",
					"preselected": false,
					"value": 398,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10001,
					"answerId": 10001,
					"iconUrl": null
				},
				{
					"id": 399,
					"pageId": 336,
					"label": "⏳ Sometimes",
					"preselected": false,
					"value": 399,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10002,
					"answerId": 10002,
					"iconUrl": null
				},
				{
					"id": 400,
					"pageId": 336,
					"label": "🏃‍♂️ Often",
					"preselected": false,
					"value": 400,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10003,
					"answerId": 10003,
					"iconUrl": null
				},
				{
					"id": 401,
					"pageId": 336,
					"label": "♾️ All the time",
					"preselected": false,
					"value": 401,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10004,
					"answerId": 10004,
					"iconUrl": null
				}
			]
		},
		{
			"id": 325,
			"type": "multiChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "Choose areas you’d like to elevate:"
				},
				{
					"type": "paragraph",
					"data": "The choice won't limit your experience"
				},
				{
					"type": "multiChoicePlainOptions",
					"data": "grid"
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				},
				"aliasMappings": {
					"2023": [
						"research-ai",
						"brainstorm-ai",
						"ai-prompting",
						"ai-writing",
						"planning-ai",
						"data-ai",
						"genai-in-practice"
					],
					"2024": [
						"data-fundamentals",
						"presenting-data",
						"llms-ai",
						"ml-fundamentals",
						"ab-testing",
						"data-ai",
						"genai-in-practice"
					],
					"2025": [
						"research-ai",
						"ai-prompting",
						"planning-ai",
						"brainstorm-ai",
						"ai-writing",
						"ab-testing",
						"presenting-data",
						"data-ai",
						"genai-in-practice"
					],
					"2026": [
						"sm-ai",
						"research-ai",
						"creativity-ai",
						"brainstorm-ai",
						"ai-writing",
						"ab-testing",
						"data-fundamentals",
						"data-ai",
						"genai-in-practice"
					],
					"2027": [
						"creativity-ai",
						"sm-ai",
						"brainstorm-ai",
						"ab-testing",
						"genai-in-practice"
					],
					"2028": [
						"brainstorm-ai",
						"research-ai",
						"ai-writing",
						"creativity-ai",
						"genai-in-practice"
					],
					"2029": [
						"llms-ai",
						"ml-fundamentals",
						"ai-prompting",
						"data-fundamentals",
						"presenting-data",
						"gen-ai-safety",
						"data-ai",
						"genai-in-practice"
					],
					"2030": [
						"ai-writing",
						"ai-prompting",
						"sm-ai",
						"research-ai",
						"brainstorm-ai",
						"creativity-ai",
						"genai-in-practice"
					],
					"2031": [
						"research-ai",
						"ai-writing",
						"ab-testing",
						"presenting-data",
						"genai-in-practice"
					],
					"2032": [
						"planning-ai",
						"research-ai",
						"ab-testing",
						"data-fundamentals",
						"presenting-data",
						"data-ai",
						"genai-in-practice"
					],
					"2033": [
						"ab-testing",
						"planning-ai",
						"creativity-ai",
						"data-fundamentals",
						"presenting-data",
						"data-ai",
						"genai-in-practice"
					],
					"2034": [
						"data-fundamentals",
						"planning-ai",
						"ab-testing",
						"research-ai",
						"brainstorm-ai",
						"ai-writing",
						"creativity-ai",
						"data-ai",
						"genai-in-practice"
					],
					"2035": [
						"ai-prompting",
						"ai-writing",
						"llms-ai",
						"ml-fundamentals",
						"gen-ai-safety",
						"genai-in-practice"
					],
					"2036": [
						"creativity-ai",
						"sm-ai",
						"research-ai",
						"ai-prompting",
						"ai-writing",
						"brainstorm-ai",
						"genai-in-practice"
					],
					"2037": [
						"presenting-data",
						"data-fundamentals",
						"llms-ai",
						"ml-fundamentals",
						"ab-testing",
						"data-ai",
						"genai-in-practice"
					]
				}
			},
			"pageOptions": [
				{
					"id": 288,
					"pageId": 325,
					"label": "Decision-making",
					"preselected": false,
					"value": 288,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 38,
					"answerTypeId": 2023,
					"answerId": 2023,
					"iconUrl": ""
				},
				{
					"id": 289,
					"pageId": 325,
					"label": "Data analytics",
					"preselected": false,
					"value": 289,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 38,
					"answerTypeId": 2024,
					"answerId": 2024,
					"iconUrl": ""
				},
				{
					"id": 290,
					"pageId": 325,
					"label": "Research skills",
					"preselected": false,
					"value": 290,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 38,
					"answerTypeId": 2025,
					"answerId": 2025,
					"iconUrl": ""
				},
				{
					"id": 291,
					"pageId": 325,
					"label": "Marketing skills",
					"preselected": false,
					"value": 291,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 38,
					"answerTypeId": 2026,
					"answerId": 2026,
					"iconUrl": ""
				},
				{
					"id": 292,
					"pageId": 325,
					"label": "Design skills",
					"preselected": false,
					"value": 292,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 38,
					"answerTypeId": 2027,
					"answerId": 2027,
					"iconUrl": ""
				},
				{
					"id": 293,
					"pageId": 325,
					"label": "Brainstorming",
					"preselected": false,
					"value": 293,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 38,
					"answerTypeId": 2028,
					"answerId": 2028,
					"iconUrl": ""
				},
				{
					"id": 294,
					"pageId": 325,
					"label": "How LLMs work",
					"preselected": false,
					"value": 294,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 38,
					"answerTypeId": 2029,
					"answerId": 2029,
					"iconUrl": ""
				},
				{
					"id": 295,
					"pageId": 325,
					"label": "Write better",
					"preselected": false,
					"value": 295,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 38,
					"answerTypeId": 2030,
					"answerId": 2030,
					"iconUrl": ""
				},
				{
					"id": 296,
					"pageId": 325,
					"label": "SEO/ASO",
					"preselected": false,
					"value": 296,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 38,
					"answerTypeId": 2031,
					"answerId": 2031,
					"iconUrl": ""
				},
				{
					"id": 297,
					"pageId": 325,
					"label": "Project management",
					"preselected": false,
					"value": 297,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 38,
					"answerTypeId": 2032,
					"answerId": 2032,
					"iconUrl": ""
				},
				{
					"id": 298,
					"pageId": 325,
					"label": "A/B testing",
					"preselected": false,
					"value": 298,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 38,
					"answerTypeId": 2033,
					"answerId": 2033,
					"iconUrl": ""
				},
				{
					"id": 299,
					"pageId": 325,
					"label": "Product management",
					"preselected": false,
					"value": 299,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 38,
					"answerTypeId": 2034,
					"answerId": 2034,
					"iconUrl": ""
				},
				{
					"id": 300,
					"pageId": 325,
					"label": "Write better prompts",
					"preselected": false,
					"value": 300,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 38,
					"answerTypeId": 2035,
					"answerId": 2035,
					"iconUrl": ""
				},
				{
					"id": 301,
					"pageId": 325,
					"label": "Creativity",
					"preselected": false,
					"value": 301,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 38,
					"answerTypeId": 2036,
					"answerId": 2036,
					"iconUrl": ""
				},
				{
					"id": 302,
					"pageId": 325,
					"label": "Data visualization",
					"preselected": false,
					"value": 302,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 38,
					"answerTypeId": 2037,
					"answerId": 2037,
					"iconUrl": ""
				}
			]
		},
		{
			"id": 337,
			"type": "singleChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "Would you enjoy a job where routine tasks are handled for you?"
				},
				{
					"type": "singleChoiceList",
					"data": null
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": [
				{
					"id": 282,
					"pageId": 337,
					"label": "✅ Yes",
					"preselected": false,
					"value": 282,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 2017,
					"answerId": 2017,
					"iconUrl": ""
				},
				{
					"id": 283,
					"pageId": 337,
					"label": "🙅‍♂️ No",
					"preselected": false,
					"value": 283,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 2018,
					"answerId": 2018,
					"iconUrl": ""
				}
			]
		},
		{
			"id": 338,
			"type": "multiChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "Which AI tools have you used in the past?"
				},
				{
					"type": "multiChoicePlainOptions",
					"data": "grid"
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": [
				{
					"id": 402,
					"pageId": 338,
					"label": "ChatGPT",
					"preselected": false,
					"value": 402,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10005,
					"answerId": 10005,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/chatgpt.png"
				},
				{
					"id": 403,
					"pageId": 338,
					"label": "Gemini",
					"preselected": false,
					"value": 403,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10006,
					"answerId": 10006,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/gemini.png"
				},
				{
					"id": 404,
					"pageId": 338,
					"label": "Midjourney",
					"preselected": false,
					"value": 404,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10007,
					"answerId": 10007,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/midjourney.png"
				},
				{
					"id": 435,
					"pageId": 338,
					"label": "DALL-E",
					"preselected": false,
					"value": 0,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10038,
					"answerId": 10038,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/dall-e.png"
				},
				{
					"id": 436,
					"pageId": 338,
					"label": "Claude",
					"preselected": false,
					"value": 0,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10039,
					"answerId": 10039,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/claude.png"
				},
				{
					"id": 437,
					"pageId": 338,
					"label": "Microsoft Copilot",
					"preselected": false,
					"value": 0,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10040,
					"answerId": 10040,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/copilot.png"
				},
				{
					"id": 438,
					"pageId": 338,
					"label": "Canva Magic Design",
					"preselected": false,
					"value": 0,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10041,
					"answerId": 10041,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/canva.webp"
				},
				{
					"id": 439,
					"pageId": 338,
					"label": "Grammarly",
					"preselected": false,
					"value": 0,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10042,
					"answerId": 10042,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/grammarly.png"
				},
				{
					"id": 440,
					"pageId": 338,
					"label": "Perplexity",
					"preselected": false,
					"value": 0,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10043,
					"answerId": 10043,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/perplexity.webp"
				},
				{
					"id": 441,
					"pageId": 338,
					"label": "NightCafe",
					"preselected": false,
					"value": 0,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10044,
					"answerId": 10044,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/nightcafe.webp"
				},
				{
					"id": 442,
					"pageId": 338,
					"label": "Picsart",
					"preselected": false,
					"value": 0,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10045,
					"answerId": 10045,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/picsart.png"
				},
				{
					"id": 443,
					"pageId": 338,
					"label": "Adobe Firefly",
					"preselected": false,
					"value": 0,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10046,
					"answerId": 10046,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/adobefirefly.png"
				},
				{
					"id": 444,
					"pageId": 338,
					"label": "Leonardo AI",
					"preselected": false,
					"value": 0,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10047,
					"answerId": 10047,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/leonardo.png"
				},
				{
					"id": 445,
					"pageId": 338,
					"label": "Jasper",
					"preselected": false,
					"value": 0,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10048,
					"answerId": 10048,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/jasper.png"
				},
				{
					"id": 446,
					"pageId": 338,
					"label": "Lumen5",
					"preselected": false,
					"value": 0,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10049,
					"answerId": 10049,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/lumen5.png"
				},
				{
					"id": 447,
					"pageId": 338,
					"label": "Notion",
					"preselected": false,
					"value": 0,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10050,
					"answerId": 10050,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/notion.png"
				}
			]
		},
		{
			"id": 339,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/visionarytechnology.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Great!",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "Automating routine tasks frees up your time for creative and impactful work. We’ll help you master the skills needed to excel in such roles!",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 340,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/interiordesign.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Awesome!",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "If you prefer being involved in every step, you’re not alone. Many tech jobs value those who take full control of their work. We’re here to support your journey!",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 341,
			"type": "singleChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "Is there something special you wish to achieve in the next year?"
				},
				{
					"type": "singleChoiceList",
					"data": null
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": [
				{
					"id": 409,
					"pageId": 341,
					"label": "🏠 Buy a house",
					"preselected": false,
					"value": 409,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10012,
					"answerId": 10012,
					"iconUrl": null
				},
				{
					"id": 410,
					"pageId": 341,
					"label": "🌴 Dream vacation",
					"preselected": false,
					"value": 410,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10013,
					"answerId": 10013,
					"iconUrl": null
				},
				{
					"id": 411,
					"pageId": 341,
					"label": "💍 Perfect wedding",
					"preselected": false,
					"value": 411,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10014,
					"answerId": 10014,
					"iconUrl": null
				},
				{
					"id": 412,
					"pageId": 341,
					"label": "💰 Save more",
					"preselected": false,
					"value": 412,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10015,
					"answerId": 10015,
					"iconUrl": null
				}
			]
		},
		{
			"id": 342,
			"type": "singleChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "Are you more motivated by personal growth or job security in your career?"
				},
				{
					"type": "singleChoiceList",
					"data": null
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": [
				{
					"id": 413,
					"pageId": 342,
					"label": "🌱 Personal growth",
					"preselected": false,
					"value": 413,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10016,
					"answerId": 10016,
					"iconUrl": null
				},
				{
					"id": 414,
					"pageId": 342,
					"label": "🛡️ Security",
					"preselected": false,
					"value": 414,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10017,
					"answerId": 10017,
					"iconUrl": null
				}
			]
		},
		{
			"id": 344,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/environment.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Personal growth",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "AI will challenge and stretch your abilities, opening doors to exciting new areas of expertise that you can explore and master.",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 345,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/securityon.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Security",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "By learning AI, you’re safeguarding your position, ensuring that you have the tools to stay competitive no matter how the industry evolves.",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 343,
			"type": "singleChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "When you learn something new, do you do it for the joy of discovery, or because you want to apply it to your life?"
				},
				{
					"type": "singleChoiceList",
					"data": null
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": [
				{
					"id": 415,
					"pageId": 343,
					"label": "🌟 Joy of discovery",
					"preselected": false,
					"value": 415,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10018,
					"answerId": 10018,
					"iconUrl": null
				},
				{
					"id": 416,
					"pageId": 343,
					"label": "🛠️ Applying it to life",
					"preselected": false,
					"value": 416,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10019,
					"answerId": 10019,
					"iconUrl": null
				}
			]
		},
		{
			"id": 346,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/explore.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Joy of discovery",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "Let us show you an exciting world of exploration, where every new concept can spark curiosity and reveal fascinating insights about the world around you.",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 347,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/done.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Applying it to life",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "AI is incredibly practical—you can immediately use what you learn to make everyday decisions smarter and more efficient, from managing your time to understanding trends.",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 348,
			"type": "singleChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "Do you think learning something new requires a lot of time, or can quick, efficient learning be enough to get started?"
				},
				{
					"type": "singleChoiceList",
					"data": null
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": [
				{
					"id": 417,
					"pageId": 348,
					"label": "⏳ A lot of time",
					"preselected": false,
					"value": 417,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10020,
					"answerId": 10020,
					"iconUrl": null
				},
				{
					"id": 418,
					"pageId": 348,
					"label": "⚡ Quick learning",
					"preselected": false,
					"value": 418,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10021,
					"answerId": 10021,
					"iconUrl": null
				}
			]
		},
		{
			"id": 349,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/gardening.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "A lot of time",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "With AI, the more time you invest, the more valuable and impactful your knowledge will become, helping you stay ahead.",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 350,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/makerlaunch.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Quick learning",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "AI is easy to learn and can be learned quickly—our lessons are designed to give you fast results, so you can start applying it right away.",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 351,
			"type": "singleChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "How do you usually approach learning something new? Do you prefer hands-on experience or more theoretical knowledge?"
				},
				{
					"type": "singleChoiceList",
					"data": null
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": [
				{
					"id": 419,
					"pageId": 351,
					"label": "🧰 Hands on",
					"preselected": false,
					"value": 419,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10022,
					"answerId": 10022,
					"iconUrl": null
				},
				{
					"id": 420,
					"pageId": 351,
					"label": "📚 Theoretical knowledge",
					"preselected": false,
					"value": 420,
					"navigation": 1,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10023,
					"answerId": 10023,
					"iconUrl": null
				}
			]
		},
		{
			"id": 352,
			"type": "singleChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "What would you do with the time saved from cutting out boring tasks?"
				},
				{
					"type": "singleChoiceList",
					"data": null
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": [
				{
					"id": 405,
					"pageId": 352,
					"label": "🎨 Do a more strategic or creative work",
					"preselected": false,
					"value": 405,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10008,
					"answerId": 10008,
					"iconUrl": null
				},
				{
					"id": 406,
					"pageId": 352,
					"label": " 🛋️ Relax",
					"preselected": false,
					"value": 406,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10009,
					"answerId": 10009,
					"iconUrl": null
				},
				{
					"id": 407,
					"pageId": 352,
					"label": "📚 Learn new skills",
					"preselected": false,
					"value": 407,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10010,
					"answerId": 10010,
					"iconUrl": null
				},
				{
					"id": 408,
					"pageId": 352,
					"label": "🏡 Spend time with friends and family",
					"preselected": false,
					"value": 408,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10011,
					"answerId": 10011,
					"iconUrl": null
				}
			]
		},
		{
			"id": 353,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/personaltrainer.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Hands on",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "Since you prefer to dive right in, we will customize your lessons so you learn while working with real tools, experimenting with real life examples.",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 354,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/exams.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Theoretical knowledge",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "With your preference for understanding concepts deeply, we will teach you the theory behind AI, so you can fully grasp how it works before applying it.",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 355,
			"type": "singleChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "Do you sometimes need a friendly push to keep you moving forward?"
				},
				{
					"type": "singleChoiceList",
					"data": null
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": [
				{
					"id": 421,
					"pageId": 355,
					"label": "🙋‍♂️ Yes",
					"preselected": false,
					"value": 421,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10024,
					"answerId": 10024,
					"iconUrl": null
				},
				{
					"id": 422,
					"pageId": 355,
					"label": "🤷‍♂️ Sometimes",
					"preselected": false,
					"value": 422,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10025,
					"answerId": 10025,
					"iconUrl": null
				},
				{
					"id": 423,
					"pageId": 355,
					"label": "🙅‍♂️ Not at all",
					"preselected": false,
					"value": 423,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10026,
					"answerId": 10026,
					"iconUrl": null
				}
			]
		},
		{
			"id": 356,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/nexttasks.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Great!",
					"style": {
						"marginBottom": "12px",
						"textAlign": "left"
					}
				},
				{
					"type": "paragraph",
					"data": "A friendly push here and there can be an excellent motivator! Our customized reminders will make it easier for you to form a learning habit and stay on track.",
					"style": {
						"marginBottom": "48px",
						"textAlign": "left"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 358,
			"type": "multiChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "Do you have a specific reason you want to upskill?"
				},
				{
					"type": "multiChoicePlainOptions",
					"data": "list"
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": [
				{
					"id": 424,
					"pageId": 358,
					"label": "💵 Make more money",
					"preselected": false,
					"value": 424,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10027,
					"answerId": 10027,
					"iconUrl": null
				},
				{
					"id": 425,
					"pageId": 358,
					"label": "📈 Get a promotion",
					"preselected": false,
					"value": 425,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10028,
					"answerId": 10028,
					"iconUrl": null
				},
				{
					"id": 426,
					"pageId": 358,
					"label": "🔄 Change my career",
					"preselected": false,
					"value": 426,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10029,
					"answerId": 10029,
					"iconUrl": null
				},
				{
					"id": 427,
					"pageId": 358,
					"label": "🛡️ Keep my job",
					"preselected": false,
					"value": 427,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10030,
					"answerId": 10030,
					"iconUrl": null
				},
				{
					"id": 428,
					"pageId": 358,
					"label": "💼 Side gig",
					"preselected": false,
					"value": 428,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10031,
					"answerId": 10031,
					"iconUrl": null
				},
				{
					"id": 429,
					"pageId": 358,
					"label": "🛠️ Side project",
					"preselected": false,
					"value": 429,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10032,
					"answerId": 10032,
					"iconUrl": null
				},
				{
					"id": 430,
					"pageId": 358,
					"label": "🎓 Learn for fun",
					"preselected": false,
					"value": 430,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10033,
					"answerId": 10033,
					"iconUrl": null
				}
			]
		},
		{
			"id": 359,
			"type": "singleChoice",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "Set your goal on timing"
				},
				{
					"type": "paragraph",
					"data": "Pick the amount of time you will spend on self-development daily"
				},
				{
					"type": "singleChoiceList",
					"data": null
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": [
				{
					"id": 431,
					"pageId": 359,
					"label": "🤙 Easy (5 min/day)",
					"preselected": false,
					"value": 431,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10034,
					"answerId": 10034,
					"iconUrl": null
				},
				{
					"id": 432,
					"pageId": 359,
					"label": "👌 Common (10 min/day)",
					"preselected": false,
					"value": 432,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10035,
					"answerId": 10035,
					"iconUrl": null
				},
				{
					"id": 433,
					"pageId": 359,
					"label": "🤘 Serious (15 min/day)",
					"preselected": false,
					"value": 433,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10036,
					"answerId": 10036,
					"iconUrl": null
				},
				{
					"id": 434,
					"pageId": 359,
					"label": "💪 Intensive (20+ min/day)",
					"preselected": false,
					"value": 434,
					"navigation": 0,
					"legend": "",
					"questionTypeId": 20,
					"answerTypeId": 10037,
					"answerId": 10037,
					"iconUrl": null
				}
			]
		},
		{
			"id": 357,
			"type": "credibility",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				},
				"version": "awardWinning"
			},
			"pageOptions": []
		},
		{
			"id": 360,
			"type": "planBuilding",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/statisticchart.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Hey, nice to meet you!"
				},
				{
					"type": "paragraph",
					"data": "We’ll help boost your productivity and earn more money. First, let’s dive into your personality and tailor your personal plan."
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 361,
			"type": "planSummary",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 362,
			"type": "emailUpdate",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/statisticchart.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Hey, nice to meet you!"
				},
				{
					"type": "paragraph",
					"data": "We’ll help boost your productivity and earn more money. First, let’s dive into your personality and tailor your personal plan."
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 363,
			"type": "paywall",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/statisticchart.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Hey, nice to meet you!"
				},
				{
					"type": "paragraph",
					"data": "We’ll help boost your productivity and earn more money. First, let’s dive into your personality and tailor your personal plan."
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 364,
			"type": "completeAccountRegistration",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/statisticchart.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Hey, nice to meet you!"
				},
				{
					"type": "paragraph",
					"data": "We’ll help boost your productivity and earn more money. First, let’s dive into your personality and tailor your personal plan."
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 365,
			"type": "downloadApp",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "image",
					"data": "https://blob.sololearn.com/web-assets/undraw/statisticchart.svg",
					"style": {
						"height": "30vh",
						"margin": "5vh 0"
					}
				},
				{
					"type": "h2",
					"data": "Hey, nice to meet you!"
				},
				{
					"type": "paragraph",
					"data": "We’ll help boost your productivity and earn more money. First, let’s dive into your personality and tailor your personal plan."
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 366,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "💙🫰💙"
				},
				{
					"type": "h1",
					"data": "People love Sololearn",
					"style": {
						"marginBottom": "32px"
					}
				},
				{
					"type": "testimonial",
					"review": {
						"reviewer": "Kimberly",
						"review": "<p>\"Lessons are quick so you can learn at anytime and since <strong>it’s like a game</strong>, it’s very motivating!\"</p>",
						"avatarUrl": "https://blob.sololearn.com/web-assets/kimberly.png",
						"imageUrl": "https://blob.sololearn.com/web-assets/undraw/winners.svg"
					},
					"style": {
						"marginBottom": "48px"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		},
		{
			"id": 367,
			"type": "generic",
			"pageName": "PsychoAttack_fake_path_v2",
			"title": "",
			"showBackButton": false,
			"cta": "",
			"content": [
				{
					"type": "h2",
					"data": "💙🫰💙"
				},
				{
					"type": "h1",
					"data": "People love Sololearn",
					"style": {
						"marginBottom": "32px"
					}
				},
				{
					"type": "testimonial",
					"review": {
						"reviewer": "Roger",
						"review": "<p>\"The courses use excellent teaching methods. You <strong>practice</strong> a lot and can prompt directly in the app. I'm really enjoying it.\"</p>",
						"avatarUrl": "https://blob.sololearn.com/web-assets/roger.png",
						"imageUrl": "https://blob.sololearn.com/web-assets/undraw/onlinetest.svg"
					},
					"style": {
						"marginBottom": "48px"
					}
				}
			],
			"style": null,
			"meta": {
				"navigation": {
					"courseNavigation": 0,
					"showAllNavigation": 1
				}
			},
			"pageOptions": []
		}
	],
	"flow": {
		"flowId": 52,
		"startPageId": 368,
		"name": null,
		"navigations": [
			{
				"id": 10913,
				"flowId": 52,
				"pageId": 368,
				"value": 0,
				"nextPageId": 307,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 2
			},
			{
				"id": 10914,
				"flowId": 52,
				"pageId": 307,
				"value": 0,
				"nextPageId": 309,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 273,
					"preselected": false,
					"value": 273,
					"answerTypeId": 2008,
					"questionTypeId": 20,
					"iconUrl": "",
					"label": "📈 15%"
				},
				"progress": 4
			},
			{
				"id": 10915,
				"flowId": 52,
				"pageId": 307,
				"value": 1,
				"nextPageId": 308,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 274,
					"preselected": false,
					"value": 274,
					"answerTypeId": 2009,
					"questionTypeId": 20,
					"iconUrl": "",
					"label": " 🚀 39%"
				},
				"progress": 4
			},
			{
				"id": 10916,
				"flowId": 52,
				"pageId": 307,
				"value": 2,
				"nextPageId": 309,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 275,
					"preselected": false,
					"value": 275,
					"answerTypeId": 2010,
					"questionTypeId": 20,
					"iconUrl": "",
					"label": "❌ No increase"
				},
				"progress": 4
			},
			{
				"id": 10917,
				"flowId": 52,
				"pageId": 308,
				"value": 0,
				"nextPageId": 310,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 6
			},
			{
				"id": 10918,
				"flowId": 52,
				"pageId": 309,
				"value": 0,
				"nextPageId": 310,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 6
			},
			{
				"id": 10919,
				"flowId": 52,
				"pageId": 310,
				"value": 0,
				"nextPageId": 311,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 278,
					"preselected": false,
					"value": 278,
					"answerTypeId": 2013,
					"questionTypeId": 20,
					"iconUrl": "",
					"label": "⏳ 25%"
				},
				"progress": 8
			},
			{
				"id": 10920,
				"flowId": 52,
				"pageId": 310,
				"value": 1,
				"nextPageId": 312,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 277,
					"preselected": false,
					"value": 277,
					"answerTypeId": 2012,
					"questionTypeId": 20,
					"iconUrl": "",
					"label": "⏱️ 10%"
				},
				"progress": 8
			},
			{
				"id": 10921,
				"flowId": 52,
				"pageId": 310,
				"value": 2,
				"nextPageId": 312,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 276,
					"preselected": false,
					"value": 276,
					"answerTypeId": 2011,
					"questionTypeId": 20,
					"iconUrl": "",
					"label": "🐢 Very little"
				},
				"progress": 8
			},
			{
				"id": 10922,
				"flowId": 52,
				"pageId": 311,
				"value": 0,
				"nextPageId": 313,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 10
			},
			{
				"id": 10923,
				"flowId": 52,
				"pageId": 312,
				"value": 0,
				"nextPageId": 313,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 10
			},
			{
				"id": 10924,
				"flowId": 52,
				"pageId": 313,
				"value": 0,
				"nextPageId": 314,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 279,
					"preselected": false,
					"value": 279,
					"answerTypeId": 2014,
					"questionTypeId": 20,
					"iconUrl": "",
					"label": "🌟 Super easy"
				},
				"progress": 12
			},
			{
				"id": 10925,
				"flowId": 52,
				"pageId": 313,
				"value": 1,
				"nextPageId": 315,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 280,
					"preselected": false,
					"value": 280,
					"answerTypeId": 2015,
					"questionTypeId": 20,
					"iconUrl": "",
					"label": "🌿 Somewhat easy"
				},
				"progress": 12
			},
			{
				"id": 10926,
				"flowId": 52,
				"pageId": 313,
				"value": 2,
				"nextPageId": 316,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 281,
					"preselected": false,
					"value": 281,
					"answerTypeId": 2016,
					"questionTypeId": 20,
					"iconUrl": "",
					"label": "🧩 A bit challenging"
				},
				"progress": 12
			},
			{
				"id": 10927,
				"flowId": 52,
				"pageId": 316,
				"value": 0,
				"nextPageId": 366,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 14
			},
			{
				"id": 10928,
				"flowId": 52,
				"pageId": 315,
				"value": 0,
				"nextPageId": 366,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 14
			},
			{
				"id": 10929,
				"flowId": 52,
				"pageId": 314,
				"value": 0,
				"nextPageId": 366,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 14
			},
			{
				"id": 10930,
				"flowId": 52,
				"pageId": 317,
				"value": 0,
				"nextPageId": 318,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 282,
					"preselected": false,
					"value": 282,
					"answerTypeId": 2017,
					"questionTypeId": 20,
					"iconUrl": "",
					"label": "✅ Yes"
				},
				"progress": 18
			},
			{
				"id": 10931,
				"flowId": 52,
				"pageId": 317,
				"value": 1,
				"nextPageId": 319,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 283,
					"preselected": false,
					"value": 283,
					"answerTypeId": 2018,
					"questionTypeId": 20,
					"iconUrl": "",
					"label": "🙅‍♂️ No"
				},
				"progress": 18
			},
			{
				"id": 10932,
				"flowId": 52,
				"pageId": 317,
				"value": 2,
				"nextPageId": 320,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 284,
					"preselected": false,
					"value": 284,
					"answerTypeId": 2019,
					"questionTypeId": 20,
					"iconUrl": "",
					"label": "ℹ️ Tell me more"
				},
				"progress": 18
			},
			{
				"id": 10933,
				"flowId": 52,
				"pageId": 318,
				"value": 0,
				"nextPageId": 321,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 20
			},
			{
				"id": 10934,
				"flowId": 52,
				"pageId": 319,
				"value": 0,
				"nextPageId": 321,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 20
			},
			{
				"id": 10935,
				"flowId": 52,
				"pageId": 320,
				"value": 0,
				"nextPageId": 321,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 20
			},
			{
				"id": 10936,
				"flowId": 52,
				"pageId": 321,
				"value": 0,
				"nextPageId": 322,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 22
			},
			{
				"id": 10937,
				"flowId": 52,
				"pageId": 322,
				"value": 0,
				"nextPageId": 323,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 256,
					"preselected": false,
					"value": 1,
					"answerTypeId": 88,
					"questionTypeId": 6,
					"iconUrl": null,
					"label": "wta.age.under-18"
				},
				"progress": 25
			},
			{
				"id": 10938,
				"flowId": 52,
				"pageId": 322,
				"value": 0,
				"nextPageId": 323,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 257,
					"preselected": false,
					"value": 2,
					"answerTypeId": 18,
					"questionTypeId": 6,
					"iconUrl": null,
					"label": "wta.age.19-24"
				},
				"progress": 25
			},
			{
				"id": 10939,
				"flowId": 52,
				"pageId": 322,
				"value": 0,
				"nextPageId": 323,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 258,
					"preselected": false,
					"value": 3,
					"answerTypeId": 19,
					"questionTypeId": 6,
					"iconUrl": null,
					"label": "wta.age.25-34"
				},
				"progress": 25
			},
			{
				"id": 10940,
				"flowId": 52,
				"pageId": 322,
				"value": 0,
				"nextPageId": 323,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 259,
					"preselected": false,
					"value": 4,
					"answerTypeId": 20,
					"questionTypeId": 6,
					"iconUrl": null,
					"label": "wta.age.35-44"
				},
				"progress": 25
			},
			{
				"id": 10941,
				"flowId": 52,
				"pageId": 322,
				"value": 0,
				"nextPageId": 323,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 260,
					"preselected": false,
					"value": 5,
					"answerTypeId": 21,
					"questionTypeId": 6,
					"iconUrl": null,
					"label": "wta.age.45-over"
				},
				"progress": 25
			},
			{
				"id": 10942,
				"flowId": 52,
				"pageId": 323,
				"value": 0,
				"nextPageId": 324,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 285,
					"preselected": false,
					"value": 285,
					"answerTypeId": 2020,
					"questionTypeId": 20,
					"iconUrl": "",
					"label": "🙋‍♂️ Male"
				},
				"progress": 27
			},
			{
				"id": 10943,
				"flowId": 52,
				"pageId": 324,
				"value": 0,
				"nextPageId": 325,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 29
			},
			{
				"id": 10944,
				"flowId": 52,
				"pageId": 323,
				"value": 0,
				"nextPageId": 324,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 286,
					"preselected": false,
					"value": 286,
					"answerTypeId": 2021,
					"questionTypeId": 20,
					"iconUrl": "",
					"label": "🙋‍♀️ Female"
				},
				"progress": 27
			},
			{
				"id": 10945,
				"flowId": 52,
				"pageId": 323,
				"value": 0,
				"nextPageId": 324,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 287,
					"preselected": false,
					"value": 287,
					"answerTypeId": 2022,
					"questionTypeId": 20,
					"iconUrl": "",
					"label": "⚧️ Other"
				},
				"progress": 27
			},
			{
				"id": 10946,
				"flowId": 52,
				"pageId": 326,
				"value": 0,
				"nextPageId": 327,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 33
			},
			{
				"id": 10947,
				"flowId": 52,
				"pageId": 327,
				"value": 0,
				"nextPageId": 328,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 282,
					"preselected": false,
					"value": 282,
					"answerTypeId": 2017,
					"questionTypeId": 20,
					"iconUrl": "",
					"label": "✅ Yes"
				},
				"progress": 35
			},
			{
				"id": 10948,
				"flowId": 52,
				"pageId": 327,
				"value": 1,
				"nextPageId": 329,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 283,
					"preselected": false,
					"value": 283,
					"answerTypeId": 2018,
					"questionTypeId": 20,
					"iconUrl": "",
					"label": "🙅‍♂️ No"
				},
				"progress": 35
			},
			{
				"id": 10949,
				"flowId": 52,
				"pageId": 328,
				"value": 0,
				"nextPageId": 330,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 37
			},
			{
				"id": 10950,
				"flowId": 52,
				"pageId": 329,
				"value": 0,
				"nextPageId": 330,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 37
			},
			{
				"id": 10951,
				"flowId": 52,
				"pageId": 330,
				"value": 0,
				"nextPageId": 331,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 282,
					"preselected": false,
					"value": 282,
					"answerTypeId": 2017,
					"questionTypeId": 20,
					"iconUrl": "",
					"label": "✅ Yes"
				},
				"progress": 39
			},
			{
				"id": 10952,
				"flowId": 52,
				"pageId": 330,
				"value": 1,
				"nextPageId": 332,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 283,
					"preselected": false,
					"value": 283,
					"answerTypeId": 2018,
					"questionTypeId": 20,
					"iconUrl": "",
					"label": "🙅‍♂️ No"
				},
				"progress": 39
			},
			{
				"id": 10953,
				"flowId": 52,
				"pageId": 331,
				"value": 0,
				"nextPageId": 333,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 41
			},
			{
				"id": 10954,
				"flowId": 52,
				"pageId": 332,
				"value": 0,
				"nextPageId": 333,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 41
			},
			{
				"id": 10955,
				"flowId": 52,
				"pageId": 333,
				"value": 0,
				"nextPageId": 334,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 282,
					"preselected": false,
					"value": 282,
					"answerTypeId": 2017,
					"questionTypeId": 20,
					"iconUrl": "",
					"label": "✅ Yes"
				},
				"progress": 43
			},
			{
				"id": 10956,
				"flowId": 52,
				"pageId": 333,
				"value": 1,
				"nextPageId": 335,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 283,
					"preselected": false,
					"value": 283,
					"answerTypeId": 2018,
					"questionTypeId": 20,
					"iconUrl": "",
					"label": "🙅‍♂️ No"
				},
				"progress": 43
			},
			{
				"id": 10957,
				"flowId": 52,
				"pageId": 334,
				"value": 0,
				"nextPageId": 336,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 45
			},
			{
				"id": 10958,
				"flowId": 52,
				"pageId": 335,
				"value": 0,
				"nextPageId": 336,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 45
			},
			{
				"id": 10959,
				"flowId": 52,
				"pageId": 336,
				"value": 0,
				"nextPageId": 337,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 398,
					"preselected": false,
					"value": 398,
					"answerTypeId": 10001,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "🙅‍♀️ Never"
				},
				"progress": 50
			},
			{
				"id": 10960,
				"flowId": 52,
				"pageId": 336,
				"value": 1,
				"nextPageId": 338,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 399,
					"preselected": false,
					"value": 399,
					"answerTypeId": 10002,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "⏳ Sometimes"
				},
				"progress": 50
			},
			{
				"id": 10961,
				"flowId": 52,
				"pageId": 325,
				"value": 0,
				"nextPageId": 326,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 288,
					"preselected": false,
					"value": 288,
					"answerTypeId": 2023,
					"questionTypeId": 38,
					"iconUrl": "",
					"label": "Decision-making"
				},
				"progress": 31
			},
			{
				"id": 10962,
				"flowId": 52,
				"pageId": 325,
				"value": 0,
				"nextPageId": 326,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 289,
					"preselected": false,
					"value": 289,
					"answerTypeId": 2024,
					"questionTypeId": 38,
					"iconUrl": "",
					"label": "Data analytics"
				},
				"progress": 31
			},
			{
				"id": 10963,
				"flowId": 52,
				"pageId": 325,
				"value": 0,
				"nextPageId": 326,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 290,
					"preselected": false,
					"value": 290,
					"answerTypeId": 2025,
					"questionTypeId": 38,
					"iconUrl": "",
					"label": "Research skills"
				},
				"progress": 31
			},
			{
				"id": 10964,
				"flowId": 52,
				"pageId": 325,
				"value": 0,
				"nextPageId": 326,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 291,
					"preselected": false,
					"value": 291,
					"answerTypeId": 2026,
					"questionTypeId": 38,
					"iconUrl": "",
					"label": "Marketing skills"
				},
				"progress": 31
			},
			{
				"id": 10965,
				"flowId": 52,
				"pageId": 325,
				"value": 0,
				"nextPageId": 326,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 292,
					"preselected": false,
					"value": 292,
					"answerTypeId": 2027,
					"questionTypeId": 38,
					"iconUrl": "",
					"label": "Design skills"
				},
				"progress": 31
			},
			{
				"id": 10966,
				"flowId": 52,
				"pageId": 325,
				"value": 0,
				"nextPageId": 326,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 293,
					"preselected": false,
					"value": 293,
					"answerTypeId": 2028,
					"questionTypeId": 38,
					"iconUrl": "",
					"label": "Brainstorming"
				},
				"progress": 31
			},
			{
				"id": 10967,
				"flowId": 52,
				"pageId": 325,
				"value": 0,
				"nextPageId": 326,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 294,
					"preselected": false,
					"value": 294,
					"answerTypeId": 2029,
					"questionTypeId": 38,
					"iconUrl": "",
					"label": "How LLMs work"
				},
				"progress": 31
			},
			{
				"id": 10968,
				"flowId": 52,
				"pageId": 325,
				"value": 0,
				"nextPageId": 326,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 295,
					"preselected": false,
					"value": 295,
					"answerTypeId": 2030,
					"questionTypeId": 38,
					"iconUrl": "",
					"label": "Write better"
				},
				"progress": 31
			},
			{
				"id": 10969,
				"flowId": 52,
				"pageId": 325,
				"value": 0,
				"nextPageId": 326,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 296,
					"preselected": false,
					"value": 296,
					"answerTypeId": 2031,
					"questionTypeId": 38,
					"iconUrl": "",
					"label": "SEO/ASO"
				},
				"progress": 31
			},
			{
				"id": 10970,
				"flowId": 52,
				"pageId": 325,
				"value": 0,
				"nextPageId": 326,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 297,
					"preselected": false,
					"value": 297,
					"answerTypeId": 2032,
					"questionTypeId": 38,
					"iconUrl": "",
					"label": "Project management"
				},
				"progress": 31
			},
			{
				"id": 10971,
				"flowId": 52,
				"pageId": 325,
				"value": 0,
				"nextPageId": 326,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 298,
					"preselected": false,
					"value": 298,
					"answerTypeId": 2033,
					"questionTypeId": 38,
					"iconUrl": "",
					"label": "A/B testing"
				},
				"progress": 31
			},
			{
				"id": 10972,
				"flowId": 52,
				"pageId": 325,
				"value": 0,
				"nextPageId": 326,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 299,
					"preselected": false,
					"value": 299,
					"answerTypeId": 2034,
					"questionTypeId": 38,
					"iconUrl": "",
					"label": "Product management"
				},
				"progress": 31
			},
			{
				"id": 10973,
				"flowId": 52,
				"pageId": 325,
				"value": 0,
				"nextPageId": 326,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 300,
					"preselected": false,
					"value": 300,
					"answerTypeId": 2035,
					"questionTypeId": 38,
					"iconUrl": "",
					"label": "Write better prompts"
				},
				"progress": 31
			},
			{
				"id": 10974,
				"flowId": 52,
				"pageId": 325,
				"value": 0,
				"nextPageId": 326,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 301,
					"preselected": false,
					"value": 301,
					"answerTypeId": 2036,
					"questionTypeId": 38,
					"iconUrl": "",
					"label": "Creativity"
				},
				"progress": 31
			},
			{
				"id": 10975,
				"flowId": 52,
				"pageId": 325,
				"value": 0,
				"nextPageId": 326,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 302,
					"preselected": false,
					"value": 302,
					"answerTypeId": 2037,
					"questionTypeId": 38,
					"iconUrl": "",
					"label": "Data visualization"
				},
				"progress": 31
			},
			{
				"id": 10976,
				"flowId": 52,
				"pageId": 336,
				"value": 1,
				"nextPageId": 338,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 400,
					"preselected": false,
					"value": 400,
					"answerTypeId": 10003,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "🏃‍♂️ Often"
				},
				"progress": 50
			},
			{
				"id": 10977,
				"flowId": 52,
				"pageId": 336,
				"value": 1,
				"nextPageId": 338,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 401,
					"preselected": false,
					"value": 401,
					"answerTypeId": 10004,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "♾️ All the time"
				},
				"progress": 50
			},
			{
				"id": 10978,
				"flowId": 52,
				"pageId": 337,
				"value": 0,
				"nextPageId": 339,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 282,
					"preselected": false,
					"value": 282,
					"answerTypeId": 2017,
					"questionTypeId": 20,
					"iconUrl": "",
					"label": "✅ Yes"
				},
				"progress": 52
			},
			{
				"id": 10979,
				"flowId": 52,
				"pageId": 338,
				"value": 0,
				"nextPageId": 337,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 402,
					"preselected": false,
					"value": 402,
					"answerTypeId": 10005,
					"questionTypeId": 20,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/chatgpt.png",
					"label": "ChatGPT"
				},
				"progress": 50
			},
			{
				"id": 10980,
				"flowId": 52,
				"pageId": 338,
				"value": 0,
				"nextPageId": 337,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 403,
					"preselected": false,
					"value": 403,
					"answerTypeId": 10006,
					"questionTypeId": 20,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/gemini.png",
					"label": "Gemini"
				},
				"progress": 50
			},
			{
				"id": 10981,
				"flowId": 52,
				"pageId": 338,
				"value": 0,
				"nextPageId": 337,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 404,
					"preselected": false,
					"value": 404,
					"answerTypeId": 10007,
					"questionTypeId": 20,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/midjourney.png",
					"label": "Midjourney"
				},
				"progress": 50
			},
			{
				"id": 10982,
				"flowId": 52,
				"pageId": 337,
				"value": 1,
				"nextPageId": 340,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 283,
					"preselected": false,
					"value": 283,
					"answerTypeId": 2018,
					"questionTypeId": 20,
					"iconUrl": "",
					"label": "🙅‍♂️ No"
				},
				"progress": 52
			},
			{
				"id": 10983,
				"flowId": 52,
				"pageId": 339,
				"value": 0,
				"nextPageId": 352,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 54
			},
			{
				"id": 10984,
				"flowId": 52,
				"pageId": 340,
				"value": 0,
				"nextPageId": 352,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 54
			},
			{
				"id": 10985,
				"flowId": 52,
				"pageId": 341,
				"value": 0,
				"nextPageId": 342,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 409,
					"preselected": false,
					"value": 409,
					"answerTypeId": 10012,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "🏠 Buy a house"
				},
				"progress": 58
			},
			{
				"id": 10986,
				"flowId": 52,
				"pageId": 341,
				"value": 0,
				"nextPageId": 342,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 410,
					"preselected": false,
					"value": 410,
					"answerTypeId": 10013,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "🌴 Dream vacation"
				},
				"progress": 58
			},
			{
				"id": 10987,
				"flowId": 52,
				"pageId": 341,
				"value": 0,
				"nextPageId": 342,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 411,
					"preselected": false,
					"value": 411,
					"answerTypeId": 10014,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "💍 Perfect wedding"
				},
				"progress": 58
			},
			{
				"id": 10988,
				"flowId": 52,
				"pageId": 341,
				"value": 0,
				"nextPageId": 342,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 412,
					"preselected": false,
					"value": 412,
					"answerTypeId": 10015,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "💰 Save more"
				},
				"progress": 58
			},
			{
				"id": 10989,
				"flowId": 52,
				"pageId": 342,
				"value": 0,
				"nextPageId": 344,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 413,
					"preselected": false,
					"value": 413,
					"answerTypeId": 10016,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "🌱 Personal growth"
				},
				"progress": 60
			},
			{
				"id": 10990,
				"flowId": 52,
				"pageId": 342,
				"value": 1,
				"nextPageId": 345,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 414,
					"preselected": false,
					"value": 414,
					"answerTypeId": 10017,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "🛡️ Security"
				},
				"progress": 60
			},
			{
				"id": 10991,
				"flowId": 52,
				"pageId": 344,
				"value": 0,
				"nextPageId": 343,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 62
			},
			{
				"id": 10992,
				"flowId": 52,
				"pageId": 345,
				"value": 0,
				"nextPageId": 343,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 62
			},
			{
				"id": 10993,
				"flowId": 52,
				"pageId": 343,
				"value": 0,
				"nextPageId": 346,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 415,
					"preselected": false,
					"value": 415,
					"answerTypeId": 10018,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "🌟 Joy of discovery"
				},
				"progress": 64
			},
			{
				"id": 10994,
				"flowId": 52,
				"pageId": 343,
				"value": 1,
				"nextPageId": 347,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 416,
					"preselected": false,
					"value": 416,
					"answerTypeId": 10019,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "🛠️ Applying it to life"
				},
				"progress": 64
			},
			{
				"id": 10995,
				"flowId": 52,
				"pageId": 346,
				"value": 0,
				"nextPageId": 348,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 66
			},
			{
				"id": 10996,
				"flowId": 52,
				"pageId": 347,
				"value": 0,
				"nextPageId": 348,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 66
			},
			{
				"id": 10997,
				"flowId": 52,
				"pageId": 348,
				"value": 0,
				"nextPageId": 349,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 417,
					"preselected": false,
					"value": 417,
					"answerTypeId": 10020,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "⏳ A lot of time"
				},
				"progress": 68
			},
			{
				"id": 10998,
				"flowId": 52,
				"pageId": 348,
				"value": 1,
				"nextPageId": 350,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 418,
					"preselected": false,
					"value": 418,
					"answerTypeId": 10021,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "⚡ Quick learning"
				},
				"progress": 68
			},
			{
				"id": 10999,
				"flowId": 52,
				"pageId": 349,
				"value": 0,
				"nextPageId": 351,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 70
			},
			{
				"id": 11000,
				"flowId": 52,
				"pageId": 350,
				"value": 0,
				"nextPageId": 351,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 70
			},
			{
				"id": 11001,
				"flowId": 52,
				"pageId": 351,
				"value": 0,
				"nextPageId": 353,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 419,
					"preselected": false,
					"value": 419,
					"answerTypeId": 10022,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "🧰 Hands on"
				},
				"progress": 72
			},
			{
				"id": 11002,
				"flowId": 52,
				"pageId": 352,
				"value": 0,
				"nextPageId": 341,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 405,
					"preselected": false,
					"value": 405,
					"answerTypeId": 10008,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "🎨 Do a more strategic or creative work"
				},
				"progress": 56
			},
			{
				"id": 11003,
				"flowId": 52,
				"pageId": 352,
				"value": 0,
				"nextPageId": 341,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 406,
					"preselected": false,
					"value": 406,
					"answerTypeId": 10009,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": " 🛋️ Relax"
				},
				"progress": 56
			},
			{
				"id": 11004,
				"flowId": 52,
				"pageId": 352,
				"value": 0,
				"nextPageId": 341,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 407,
					"preselected": false,
					"value": 407,
					"answerTypeId": 10010,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "📚 Learn new skills"
				},
				"progress": 56
			},
			{
				"id": 11005,
				"flowId": 52,
				"pageId": 352,
				"value": 0,
				"nextPageId": 341,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 408,
					"preselected": false,
					"value": 408,
					"answerTypeId": 10011,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "🏡 Spend time with friends and family"
				},
				"progress": 56
			},
			{
				"id": 11006,
				"flowId": 52,
				"pageId": 351,
				"value": 1,
				"nextPageId": 354,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 420,
					"preselected": false,
					"value": 420,
					"answerTypeId": 10023,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "📚 Theoretical knowledge"
				},
				"progress": 72
			},
			{
				"id": 11007,
				"flowId": 52,
				"pageId": 353,
				"value": 0,
				"nextPageId": 367,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 75
			},
			{
				"id": 11008,
				"flowId": 52,
				"pageId": 354,
				"value": 0,
				"nextPageId": 367,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 75
			},
			{
				"id": 11009,
				"flowId": 52,
				"pageId": 355,
				"value": 0,
				"nextPageId": 356,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 421,
					"preselected": false,
					"value": 421,
					"answerTypeId": 10024,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "🙋‍♂️ Yes"
				},
				"progress": 79
			},
			{
				"id": 11010,
				"flowId": 52,
				"pageId": 355,
				"value": 0,
				"nextPageId": 356,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 422,
					"preselected": false,
					"value": 422,
					"answerTypeId": 10025,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "🤷‍♂️ Sometimes"
				},
				"progress": 79
			},
			{
				"id": 11011,
				"flowId": 52,
				"pageId": 355,
				"value": 0,
				"nextPageId": 356,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 423,
					"preselected": false,
					"value": 423,
					"answerTypeId": 10026,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "🙅‍♂️ Not at all"
				},
				"progress": 79
			},
			{
				"id": 11012,
				"flowId": 52,
				"pageId": 356,
				"value": 0,
				"nextPageId": 357,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 81
			},
			{
				"id": 11013,
				"flowId": 52,
				"pageId": 358,
				"value": 0,
				"nextPageId": 359,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 424,
					"preselected": false,
					"value": 424,
					"answerTypeId": 10027,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "💵 Make more money"
				},
				"progress": 85
			},
			{
				"id": 11014,
				"flowId": 52,
				"pageId": 358,
				"value": 0,
				"nextPageId": 359,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 425,
					"preselected": false,
					"value": 425,
					"answerTypeId": 10028,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "📈 Get a promotion"
				},
				"progress": 85
			},
			{
				"id": 11015,
				"flowId": 52,
				"pageId": 358,
				"value": 0,
				"nextPageId": 359,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 426,
					"preselected": false,
					"value": 426,
					"answerTypeId": 10029,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "🔄 Change my career"
				},
				"progress": 85
			},
			{
				"id": 11016,
				"flowId": 52,
				"pageId": 358,
				"value": 0,
				"nextPageId": 359,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 427,
					"preselected": false,
					"value": 427,
					"answerTypeId": 10030,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "🛡️ Keep my job"
				},
				"progress": 85
			},
			{
				"id": 11017,
				"flowId": 52,
				"pageId": 358,
				"value": 0,
				"nextPageId": 359,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 428,
					"preselected": false,
					"value": 428,
					"answerTypeId": 10031,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "💼 Side gig"
				},
				"progress": 85
			},
			{
				"id": 11018,
				"flowId": 52,
				"pageId": 358,
				"value": 0,
				"nextPageId": 359,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 429,
					"preselected": false,
					"value": 429,
					"answerTypeId": 10032,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "🛠️ Side project"
				},
				"progress": 85
			},
			{
				"id": 11019,
				"flowId": 52,
				"pageId": 358,
				"value": 0,
				"nextPageId": 359,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 430,
					"preselected": false,
					"value": 430,
					"answerTypeId": 10033,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "🎓 Learn for fun"
				},
				"progress": 85
			},
			{
				"id": 11020,
				"flowId": 52,
				"pageId": 359,
				"value": 0,
				"nextPageId": 360,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 431,
					"preselected": false,
					"value": 431,
					"answerTypeId": 10034,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "🤙 Easy (5 min/day)"
				},
				"progress": 87
			},
			{
				"id": 11021,
				"flowId": 52,
				"pageId": 359,
				"value": 0,
				"nextPageId": 360,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 432,
					"preselected": false,
					"value": 432,
					"answerTypeId": 10035,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "👌 Common (10 min/day)"
				},
				"progress": 87
			},
			{
				"id": 11022,
				"flowId": 52,
				"pageId": 359,
				"value": 0,
				"nextPageId": 360,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 433,
					"preselected": false,
					"value": 433,
					"answerTypeId": 10036,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "🤘 Serious (15 min/day)"
				},
				"progress": 87
			},
			{
				"id": 11023,
				"flowId": 52,
				"pageId": 359,
				"value": 0,
				"nextPageId": 360,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 434,
					"preselected": false,
					"value": 434,
					"answerTypeId": 10037,
					"questionTypeId": 20,
					"iconUrl": null,
					"label": "💪 Intensive (20+ min/day)"
				},
				"progress": 87
			},
			{
				"id": 11024,
				"flowId": 52,
				"pageId": 357,
				"value": 0,
				"nextPageId": 358,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 83
			},
			{
				"id": 11025,
				"flowId": 52,
				"pageId": 360,
				"value": 0,
				"nextPageId": 361,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 89
			},
			{
				"id": 11026,
				"flowId": 52,
				"pageId": 361,
				"value": 0,
				"nextPageId": 362,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 91
			},
			{
				"id": 11027,
				"flowId": 52,
				"pageId": 362,
				"value": 0,
				"nextPageId": 363,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 93
			},
			{
				"id": 11028,
				"flowId": 52,
				"pageId": 363,
				"value": 0,
				"nextPageId": 364,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 95
			},
			{
				"id": 11029,
				"flowId": 52,
				"pageId": 364,
				"value": 0,
				"nextPageId": 365,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 97
			},
			{
				"id": 11030,
				"flowId": 52,
				"pageId": 365,
				"value": 0,
				"nextPageId": null,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 100
			},
			{
				"id": 11031,
				"flowId": 52,
				"pageId": 338,
				"value": 0,
				"nextPageId": 337,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 435,
					"preselected": false,
					"value": 0,
					"answerTypeId": 10038,
					"questionTypeId": 20,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/dall-e.png",
					"label": "DALL-E"
				},
				"progress": 50
			},
			{
				"id": 11032,
				"flowId": 52,
				"pageId": 338,
				"value": 0,
				"nextPageId": 337,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 436,
					"preselected": false,
					"value": 0,
					"answerTypeId": 10039,
					"questionTypeId": 20,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/claude.png",
					"label": "Claude"
				},
				"progress": 50
			},
			{
				"id": 11033,
				"flowId": 52,
				"pageId": 338,
				"value": 0,
				"nextPageId": 337,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 437,
					"preselected": false,
					"value": 0,
					"answerTypeId": 10040,
					"questionTypeId": 20,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/copilot.png",
					"label": "Microsoft Copilot"
				},
				"progress": 50
			},
			{
				"id": 11034,
				"flowId": 52,
				"pageId": 338,
				"value": 0,
				"nextPageId": 337,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 438,
					"preselected": false,
					"value": 0,
					"answerTypeId": 10041,
					"questionTypeId": 20,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/canva.webp",
					"label": "Canva Magic Design"
				},
				"progress": 50
			},
			{
				"id": 11035,
				"flowId": 52,
				"pageId": 338,
				"value": 0,
				"nextPageId": 337,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 439,
					"preselected": false,
					"value": 0,
					"answerTypeId": 10042,
					"questionTypeId": 20,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/grammarly.png",
					"label": "Grammarly"
				},
				"progress": 50
			},
			{
				"id": 11036,
				"flowId": 52,
				"pageId": 338,
				"value": 0,
				"nextPageId": 337,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 440,
					"preselected": false,
					"value": 0,
					"answerTypeId": 10043,
					"questionTypeId": 20,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/perplexity.webp",
					"label": "Perplexity"
				},
				"progress": 50
			},
			{
				"id": 11037,
				"flowId": 52,
				"pageId": 338,
				"value": 0,
				"nextPageId": 337,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 441,
					"preselected": false,
					"value": 0,
					"answerTypeId": 10044,
					"questionTypeId": 20,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/nightcafe.webp",
					"label": "NightCafe"
				},
				"progress": 50
			},
			{
				"id": 11038,
				"flowId": 52,
				"pageId": 338,
				"value": 0,
				"nextPageId": 337,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 442,
					"preselected": false,
					"value": 0,
					"answerTypeId": 10045,
					"questionTypeId": 20,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/picsart.png",
					"label": "Picsart"
				},
				"progress": 50
			},
			{
				"id": 11039,
				"flowId": 52,
				"pageId": 338,
				"value": 0,
				"nextPageId": 337,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 443,
					"preselected": false,
					"value": 0,
					"answerTypeId": 10046,
					"questionTypeId": 20,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/adobefirefly.png",
					"label": "Adobe Firefly"
				},
				"progress": 50
			},
			{
				"id": 11040,
				"flowId": 52,
				"pageId": 338,
				"value": 0,
				"nextPageId": 337,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 444,
					"preselected": false,
					"value": 0,
					"answerTypeId": 10047,
					"questionTypeId": 20,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/leonardo.png",
					"label": "Leonardo AI"
				},
				"progress": 50
			},
			{
				"id": 11041,
				"flowId": 52,
				"pageId": 338,
				"value": 0,
				"nextPageId": 337,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 445,
					"preselected": false,
					"value": 0,
					"answerTypeId": 10048,
					"questionTypeId": 20,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/jasper.png",
					"label": "Jasper"
				},
				"progress": 50
			},
			{
				"id": 11042,
				"flowId": 52,
				"pageId": 338,
				"value": 0,
				"nextPageId": 337,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 446,
					"preselected": false,
					"value": 0,
					"answerTypeId": 10049,
					"questionTypeId": 20,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/lumen5.png",
					"label": "Lumen5"
				},
				"progress": 50
			},
			{
				"id": 11043,
				"flowId": 52,
				"pageId": 338,
				"value": 0,
				"nextPageId": 337,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": {
					"id": 447,
					"preselected": false,
					"value": 0,
					"answerTypeId": 10050,
					"questionTypeId": 20,
					"iconUrl": "https://sololearnblob.blob.core.windows.net/web-assets/notion.png",
					"label": "Notion"
				},
				"progress": 50
			},
			{
				"id": 11044,
				"flowId": 52,
				"pageId": 366,
				"value": 0,
				"nextPageId": 317,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 16
			},
			{
				"id": 11045,
				"flowId": 52,
				"pageId": 367,
				"value": 0,
				"nextPageId": 355,
				"platform": 1,
				"navigationDependencies": [],
				"pageOption": null,
				"progress": 77
			}
		]
	},
	"options": {},
	"checkpoints": []
} as unknown as WTAJourney);
