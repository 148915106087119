import React, { FC } from "react";
import { WTAUserReview } from "../../../types/webtoapp.types";
import FiveStarsIcon from "../../../../../staticPages/public/SlAILiteracy/components/Reviews/icons/FiveStarsIcon";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './Testimonial.scss';

type Props = {
	review: WTAUserReview;
	style: React.CSSProperties;
};

export const Testimonial: FC<Props> = ({ review, style }) => {
	useStyles(s);

	return <div style={style} className="wta-review">
		<img className="wta-review-avatar" src={review.avatarUrl} alt="" />
		<span className="wta-review-reviewer">{review.reviewer}</span>
		<div className="wta-review-review" dangerouslySetInnerHTML={{ __html: review.review }}></div>
		<img src={review.imageUrl} alt="" className="wta-review-image" />
		<div className="wta-review-stars">
			<FiveStarsIcon />
		</div>
	</div>;
};