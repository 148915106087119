/* eslint-disable dot-notation */

import { AuthService } from '../auth.service';
import { Container } from '../container.global';
import { IBaseDataTracking, ITrackOptions } from './IBaseDataTracking';
import {
	generateUUID, getAFUserId, getGuId, getCountryCode, getDeviceType, getLocaleName, getUserAgent
} from '../../private/tracking/tracking.helpers';

export const dataTrackingLSKey = 'tnasItems';

const clearStorage = (): void => {
	localStorage.removeItem(dataTrackingLSKey);
};

export const getStorageValue = <T>(): T[] => {
	const value: string | null = localStorage.getItem(dataTrackingLSKey);
	if (!value) {
		return [];
	}
	try {
		// `parse` can fail
		const parsedValue: T[] = JSON.parse(value); // could be anything so we check in next line
		return Array.isArray(parsedValue) ? parsedValue : [];
	} catch (e) {
		clearStorage();
		return [];
	}
};

const setStorageValue = <T>(data: T): void => {
	localStorage.setItem(dataTrackingLSKey, JSON.stringify(data));
};

/**
 * Adds `data` to `localStorage` which later is collected and a http request is made
 * via web workers
 * @param data any valid object according to the backend schema
 */
const trackData = <T extends IBaseDataTracking>(
	eventData: T,
	options?: ITrackOptions,
): void => {
	const authService = Container.take('global', AuthService);
	const isLoggedIn = authService?.isLoggedIn();

	if (!eventData) {
		return;
	}

	const globalInfo = {
		platform_id: '4',
		version: eventData.version || '1-0-0',
		created_at: new Date().toISOString(),
		fngrprnt_id: document.cookie.replace(
			/(?:(?:^|.*;\s*)fngprntId\s*\=\s*([^;]*).*$)|^.*$/,
			'$1',
		),
	};
	const userData = isLoggedIn
		? {
			user_id: String(authService.userInfo$.value.id),
		}
		: { user_id: '0' };

	setStorageValue([
		...getStorageValue(),
		{
			...globalInfo,
			...userData,
			...eventData,
			...generateUUID(eventData.event_name, options?.generateId),
			...getAFUserId(eventData.event_name),
			...getGuId(eventData.event_name),
			...getCountryCode(eventData.event_name),
			...getDeviceType(eventData.event_name),
			...getLocaleName(eventData.event_name),
			...getUserAgent(eventData.event_name)
		},
	]);
};

export const track = <T extends IBaseDataTracking>(
	eventData: T,
	options?: ITrackOptions,
): void => {
	if (typeof window === 'undefined') return;

	if ((window as any).AFLoaded) {
		trackData(eventData, options);
	} else {
		let isAFLoaded = false;
		window.addEventListener('AFLoaded', () => {
			if (!isAFLoaded) {
				trackData(eventData, options);
			}
			isAFLoaded = true;
		});

		setTimeout(() => {
			if (!isAFLoaded) {
				trackData(eventData, options);
				isAFLoaded = true;
			}
		}, 60000);
	}
};
