export type WTAJourney = {
	flow: WTAFlow;
	pages: WTAPage[];
	checkpoints: WTACheckpoint[];
};

export type WTACheckpoint = {
	id: number;
	name: string;
	order: number;
	pageCount: number;
};

export type WTACheckpointState = WTACheckpoint & {
	completedSteps: number;
};

export type WTACheckpointInfo = {
	position: number;
	checkPointId: number;
};

export type WTAFlow = {
	startPageId: number;
	flowId: number;
	navigations: WTANavigation[];
};

export type WTANavigation = {
	flowId: number;
	id: number;
	navigationDependencies: any[];
	nextPageId: number;
	pageId: number;
	pageOption: WTAPageOption;
	value: number;
	progress: number;
};

export type WTASelection = {
	screenId: number;
	selection: string;
};

export type WTAPage = {
	id?: number;
	meta?: WTAPageMeta;
	type: WTAPageType;
	content?: WTAComponent[];
	pageOptions?: WTAPageOption[];
	title?: string;
	showBackButton?: boolean;
	cta?: string;
	pageName?: string;
	style?: any;
	checkpointInfo?: WTACheckpointInfo;
};

export enum WTAPageType {
	generic = 'generic',
	paywall = 'paywall',
	singleChoice = 'singleChoice',
	multiChoice = 'multiChoice',
	quiz = 'quiz',
	topics = 'topics',
	loading = 'loading',
	getStarted = 'getStarted',
	emailUpdate = 'emailUpdate',
	completeAccountRegistration = 'completeAccountRegistration',
	downloadApp = 'downloadApp',
	flowInitialization = 'flowInitialization',
	aiOrHuman = 'aiOrHuman',
	credibility = 'credibility',
	planBuilding = 'planBuilding',
	planSummary = 'planSummary',
	feedback = 'feedback',
	testimonial = 'testimonial'
}

export type WTAUserReview = {
	reviewer: string;
	review: string;
	imageUrl: string;
	avatarUrl: string;
};

export type WTAComponent = {
	type: WTAComponentType;
	data?: string;
	userName?: string;
	style?: React.CSSProperties;
	questions?: WTAQuizQuestion[];
	animation?: 'KODIE';
	testimonialKeys?: string[];
	shuffledOptionIds?: number[];
	review: WTAUserReview;
};

export enum WTAComponentType {
	h1 = 'h1',
	h2 = 'h2',
	h3 = 'h3',
	h4 = 'h4',
	paragraph = 'paragraph',
	image = 'image',
	animation = 'animation',
	singleChoiceList = 'singleChoiceList',
	multiChoiceCards = 'multiChoiceCards',
	multiChoicePlainOptions = 'multiChoicePlainOptions',
	userReview = 'userReview',
	testimonial = 'testimonial',
	likeDislike = 'likeDislike',
	loader = 'loader',
	svgIcon = 'svgIcon',
	rive = 'rive',
	pageTitle = 'pageTitle',
	testimonials = 'testimonials',
	awards = 'awards',
	ageSelection = 'ageSelection',
	sololearnLogo = 'sololearnLogo'
}

export type WTAPageOption = {
	questionTypeId?: number;
	answerTypeId?: number;
	iconUrl?: string;
	id?: number;
	label: string;
	legend?: string;
	navigation?: number;
	pageId?: number;
	preselected?: boolean;
	value: number;
};

export type WTAAliasMapping = {
	[key: string]: string[];
};

export type WTAPageMeta = {
	style?: {
		background: 'white' | 'purple';
	};
	version?: string;
	aliasMappings?: WTAAliasMapping;
};

export type WTAQuizQuestion = {
	id: number;
	imageUrl: string;
	question: string;
	description: string;
	aliases: string[];
	topicIds: number[];
};

export type WTAQuizChangeHandler = (
	question: WTAQuizQuestion,
	result: 'like' | 'dislike',
	isCompleted: boolean
) => void;

export enum WTAAIorHumanAnswers {
	ai = 1,
	human = 2
}

export type WTASingleChoiceChangeHandler = (option: WTAPageOption) => void;

export type WTAMultiChoiceChangeHandler = (options: WTAPageOption[]) => void;

export type WTAPlanBuildingStep = {
	title: string;
	status: 'notStarted' | 'started' | 'interrupted' | 'resumed' | 'completed';
	question: string;
	reviewer: string;
	review: string;
	progress: number;
};
