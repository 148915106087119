import React, { FC, useState } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './MultiChoiceOptions.scss';
import { WTAMultiChoiceChangeHandler, WTAPageOption } from "../../../types/webtoapp.types";

type Props = {
	options: WTAPageOption[];
	layout: 'grid' | 'list';
	onChange: WTAMultiChoiceChangeHandler;
};

export const MultiChoiceOptions: FC<Props> = ({ options, layout, onChange }) => {
	useStyles(s);
	const [selected, setSelected] = useState<WTAPageOption[]>([]);

	const onSelect = (option: WTAPageOption) => {
		const selectedItems = selected.includes(option)
			? selected.filter(v => v.answerTypeId !== option.answerTypeId)
			: [...selected, option];
		setSelected(selectedItems);
		onChange(selectedItems);
	};

	return <div className={`wta-multi-options ${layout}`}>
		{options.map(o => (<div
			onClick={() => onSelect(o)}
			key={o.value}
			className={`wta-multi-options-item ${layout} ${o.iconUrl ? 'large' : ''} ${selected.includes(o) ? 'selected' : ''}`}
		>
			{o.iconUrl && <div className={`wta-multi-options-icon ${selected.includes(o) ? 'selected' : ''}`}><img src={o.iconUrl} /></div>}
			<span>{o.label}</span>
			{selected.includes(o) && <div className={`wta-multi-options-check-container ${layout}`}><div className="wta-multi-options-check"></div></div>}
		</div>))}
	</div>;
};