import React, { FC, useEffect } from "react";
import { WebToAppService } from "../../../services/webtoapp.service";
import { Container } from "../../../../../symphony";
import { Padding } from "../../shared/Padding/Padding";
import { Heading } from "../../pageComponents/Heading/Heading";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WTASololearnCredibilityPage.scss';
import { SlIcon } from "@sololearnorg/icons";
import { WTAPage } from "../../../types/webtoapp.types";
import { Animation } from "../../pageComponents/Animation/Animation";
import { Awards } from "../../pageComponents/Awards/Awards";
import FiveStarsIcon from "../../../../../staticPages/public/SlAILiteracy/components/Reviews/icons/FiveStarsIcon";

type Props = {
	version: string;
};

export const WTASololearnCredibilityPage: FC<Props> = ({ version }) => {
	useStyles(s);

	const { footer, progressBar } = Container.take('wta', WebToAppService);

	useEffect(() => {
		footer.enable();
		progressBar.hide();
		if (version === 'awardWinning') {
			footer.style({
				backgroundColor: '#8859db'
			});
		}
		return () => {
			footer.style(null);
		};
	}, []);

	return <Padding className="wta-credibility">
		{version === 'featuredIn' && <>
			<div className="wta-credibility-titles">
				<div className="wta-credibility-titles-logo"><SlIcon iconId="sololearn" /></div>
				<p className="wta-credibility-titles-big">Join 30+ million people</p>
				<p className="wta-credibility-titles-small">who already use Sololearn</p>
			</div>

			<div className="wta-credibility-quote">
				<img src="https://cdn-icons-png.flaticon.com/256/4992/4992676.png" />
				<p className="wta-credibility-quote-paragraph">A profound impact on millions of peoples’ financial futures</p>
				<img alt="tech-crunch" src="https://blob.sololearn.com/web-assets/tech-crunch.png" />
			</div>

			<div className="wta-credibility-mentioned">
				<p className="wta-credibility-mentioned-title">Mentioned in</p>

				<div className="wta-credibility-mentioned-list">
					<div className="wta-credibility-mentioned-list-item"><img alt="thenewyorktimes" src="https://blob.sololearn.com/web-assets/newyorktimes_.png" /></div>
					<div className="wta-credibility-mentioned-list-item"><img alt="businessinsider" src="https://blob.sololearn.com/web-assets/businessinsider.png" /></div>
					<div className="wta-credibility-mentioned-list-item"><img alt="forbes" src="https://blob.sololearn.com/web-assets/forbes.png" /></div>
					<div className="wta-credibility-mentioned-list-item"><img alt="tech-crunch" src="https://blob.sololearn.com/web-assets/tech-crunch.png" /></div>
					<div className="wta-credibility-mentioned-list-item"><img alt="venturebeat" src="https://blob.sololearn.com/web-assets/venturebeat.png" /></div>
					<div className="wta-credibility-mentioned-list-item"><img alt="lifehacker" src="https://blob.sololearn.com/web-assets/lifehacker.svg" /></div>
				</div>

			</div>
		</>}
		{version === 'awardWinning' && <>
			<div className="wta-credibility-v2-heading">
				<div className="wta-credibility-titles-logo"><SlIcon iconId="sololearn" /></div>
				<p className="wta-credibility-v2-title">Join <strong>30M+</strong> learners</p>
				<p className="wta-credibility-v2-description"><strong>Master AI</strong> with our <br /> <strong>award-winning</strong> app!</p>
			</div>
			<div className="wta-credibility-v2-animation-container">
				<Animation animationName="appleAward" style={{}} />
			</div>
		</>}

	</Padding>;
};