import { SlIcon } from "@sololearnorg/icons";
import React, { FC } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './SololearnLogo.scss';

type Props = {
	style: React.CSSProperties;
};

export const SololearnLogo: FC<Props> = ({ style }) => {
	useStyles(s);

	return <div className="wta-logo" style={style}>
		<div className="wta-logo-container"><SlIcon iconId="sololearn" /></div>
	</div>;
};