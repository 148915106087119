import React, { FC, useEffect } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WTAMultiChoicePage.scss';
import { Padding } from "../../shared/Padding/Padding";
import { Container } from "../../../../../symphony";
import { WebToAppService } from "../../../services/webtoapp.service";
import { WTAComponentDrawer } from "../../pageComponents/WTAComponentDrawer/WTAComponentDrawer";
import { WTAPage } from "../../../types/webtoapp.types";

type Props = {
	page: WTAPage;
};

export const WTAMultiChoicePage: FC<Props> = ({ page }) => {
	useStyles(s);

	const { footer, progressBar, multiChoicePageHandler, saveAliases } = Container.take('wta', WebToAppService);

	useEffect(() => {
		footer.disable();
		progressBar.show();
	}, []);

	return <Padding className="wta-multichoice">
		<WTAComponentDrawer
			components={page.content}
			pageOptions={page.pageOptions}
			handlers={{
				onMultiChoiceChange: page.meta?.aliasMappings
					? (options) => { saveAliases(options, page.meta.aliasMappings); }
					: multiChoicePageHandler
			}}
		/>
	</Padding>;
};
