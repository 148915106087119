import React, { FC } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './ProgressBar.scss';
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { WebToAppStore } from "../../../stores/webtoapp.store";

type Props = {};

export const ProgressBar: FC<Props> = () => {
	useStyles(s);

	const {
		progressValue,
		checkpoints,
		checkpointStates,
		checkpointsCompleted
	} = useContainerData('wta', WebToAppStore, [
		'progressValue', 'checkpoints',
		'checkpointStates', 'checkpointsCompleted'
	]);

	const showCheckpointProgress = (checkpointId: number) => {
		return checkpointStates.find(ch => ch.id === checkpointId).completedSteps > 0;
	};

	const checkpointProgressWidth = (checkpointId: number) => {
		const checkpointState = checkpointStates.find(ch => ch.id === checkpointId);
		return checkpointState.completedSteps / checkpointState.pageCount * 100;
	};

	const checkpointCompletionState = (checkpointId: number): 'not-started' | 'in-progress' | 'completed' => {
		const checkpointState = checkpointStates.find(ch => ch.id === checkpointId);
		if (checkpointState.completedSteps === checkpointState.pageCount) {
			return 'completed';
		} else if (checkpointState.completedSteps === 0) {
			return 'not-started';
		}
		return 'in-progress';
	};

	const currentCheckpointTitle = (): string => {
		const inProgress = checkpointStates.find(c => c.completedSteps !== 0 && c.completedSteps < c.pageCount);
		if (inProgress) {
			return inProgress.name;
		}
		const indexOfNotStarted = checkpointStates.indexOf(checkpointStates.find(c => c.completedSteps === 0));
		console.log(indexOfNotStarted);
		if (indexOfNotStarted === -1) {
			return checkpointStates[checkpointStates.length - 1].name;
		}
		return checkpointStates[indexOfNotStarted - 1]?.name;
	};

	return (
		<>
			{!checkpoints && <div className={`progress-bar`}>
				<div
					className={`progress-bar-fill`}
					style={{ width: `${progressValue}%` }}
				></div>
			</div>}
			{checkpoints && !checkpointsCompleted && <div className="checkpoints-bar">
				<div className="checkpoints-bar-container">
					{checkpoints.map(c => (<div
						key={c.name}
						style={{
							width: `${100 / checkpoints.length}%`
						}}
						className={`checkpoints-bar-segment ${checkpointCompletionState(c.id)}`}
					>
						{showCheckpointProgress(c.id) && <div
							className="checkpoints-bar-progress"
							style={{
								width: `${checkpointProgressWidth(c.id)}%`
							}}
						></div>}
					</div>))}
				</div>
				<p className="checkpoints-bar-title">
					{currentCheckpointTitle()}
				</p>
			</div>}
			{checkpoints && checkpointsCompleted && <div className="progress-bar-space"></div>}
		</>
	);
};
