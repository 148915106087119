import React, { FC, useEffect } from "react";
import { WTAComponentType, WTAPage } from "../../../types/webtoapp.types";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WTAGetStartedPage.scss';
import { Container } from "../../../../../symphony";
import { WebToAppService } from "../../../services/webtoapp.service";
import { WTAComponentDrawer } from "../../pageComponents/WTAComponentDrawer/WTAComponentDrawer";

type Props = {
	page: WTAPage;
};

export const WTAGetStartedPage: FC<Props> = ({ page }) => {
	useStyles(s);

	const { footer, progressBar, onAgeSelect } = Container.take('wta', WebToAppService);

	useEffect(() => {
		const hasAgeSelection = page.content.find(c => c.type === WTAComponentType.ageSelection);
		if (!!hasAgeSelection) {
			footer.hide();
		} else {
			footer.getStarted();
		}
		progressBar.hide();
	}, []);

	return <WTAComponentDrawer
		components={page.content}
		pageOptions={page.pageOptions}
		handlers={{ onAgeSelect }}
	/>;
};