import React, { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container } from "../../symphony";
import { WebToAppService } from "../private/services/webtoapp.service";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WebToApp.scss';
import { WTAPageDrawer } from "../private/components/pages/WTAPageDrawer/WTAPageDrawer";
import { SlHelmet } from "../../shared/public/SlHelmet/SlHelmet";
import {
	useIcons,
	sololearn,
	googlePlayEditorsChoice,
	appleGenerativeAward,
	purpleDots,
	reviewBackground,
	appleAward,
	sololearnRound,
	pencil
} from '@sololearnorg/icons';

type Props = {};

export const WebToApp: FC<Props> = () => {
	useStyles(s);
	useIcons([
		sololearn,
		appleGenerativeAward,
		googlePlayEditorsChoice,
		purpleDots,
		reviewBackground,
		appleAward,
		sololearnRound,
		pencil
	]);
	const { search } = useLocation();

	const { initFlow } = Container.take('wta', WebToAppService);

	const resizeListener = () => {
		const a = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${a}px`);
	};

	useEffect(() => {
		initFlow(search);
		resizeListener();
		window.addEventListener('resize', resizeListener);
		return () => {
			window.removeEventListener('resize', resizeListener);
		};
	}, []);

	return <>
		<SlHelmet
			title="Sololearn: Learn AI"
			description="Top AI lessons by experts at Sololearn: Dive into cutting-edge AI technologies with comprehensive, beginner-friendly courses designed by industry professionals. Elevate your skills in machine learning, neural networks, and more with interactive lessons and real-world projects. Join our vibrant community and start your AI journey today!"
		/>
		<div className="wta-container">
			<div className="wta-inner-container">
				<WTAPageDrawer />
			</div>
		</div>
	</>;
};
