import { Observable } from 'rxjs';

import {
	AxiosInstanceType,
	SlAxiosInstanceService,
} from '../private/services/sl-axios-instance.service';
import { IApiUrls } from './global';
import { SlApiContext } from '../private/api-context';
import { AuthLevel, Authorize } from '../private/authorize.decorator';
import { constructUrl } from '../private/utils/httpParamsUtils';
import {
	IOnboardingAnswerPayload,
	IDynamicOnboardingApi,
	IOnboardingData,
	ILearningMaterialsDetails,
} from '../private/dynamic-onboarding-api/dynamic-onboarding-api.interface';
import { Service, Container } from '../../symphony';
import { WTAJourney } from '../../_webtoapp/private/types/webtoapp.types';

@Service()
export class DynamicOnboardingApi implements IDynamicOnboardingApi {
	private environmentUrl: string;

	private axiosInstance: AxiosInstanceType;

	constructor() {
		this.environmentUrl = (
			Container.take('global', 'envUrl') as IApiUrls
		)?.dynamicOnboardingHost;

		this.axiosInstance = Container.take(
			SlApiContext,
			SlAxiosInstanceService,
		).axios;
	}

	@Authorize(AuthLevel.authenticated)
	public getDynamicOnboardingFlow(
		alias?: string,
	): Observable<IOnboardingData> {
		return this.axiosInstance.get(
			constructUrl(this.environmentUrl, 'api/journey'),
			{
				params: {
					'landing-page-alias': alias,
				},
			},
		);
	}

	@Authorize(AuthLevel.authenticated)
	public getWTAJourney(
		landingPageAlias?: string
	): Observable<WTAJourney> {
		const params = { 'landing-page-alias': landingPageAlias };
		return this.axiosInstance.get(
			constructUrl(this.environmentUrl, 'api/v2/Journey'),
			{ params }
		);
	}

	@Authorize(AuthLevel.authenticated)
	public postUserAnswers(
		payload: IOnboardingAnswerPayload[],
	): Observable<null> {
		return this.axiosInstance.post(
			constructUrl(this.environmentUrl, 'api/useranswers'),
			payload,
		);
	}

	@Authorize(AuthLevel.authenticated)
	public getLearningMaterialsDetails(): Observable<ILearningMaterialsDetails> {
		return this.axiosInstance.get(
			constructUrl(
				this.environmentUrl,
				'api/recommendation/learningmaterialsdetails',
			),
		);
	}
}

export * from '../private/dynamic-onboarding-api/dynamic-onboarding-api.interface';
