import React, { FC, useEffect, useState } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WTAPlanSummary.scss';
import { Container } from "../../../../../symphony";
import { WebToAppService } from "../../../services/webtoapp.service";
import { WTAPage } from "../../../types/webtoapp.types";
import { Heading } from "../../pageComponents/Heading/Heading";
import { Padding } from "../../shared/Padding/Padding";
import { Image } from "../../pageComponents/Image/Image";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { WebToAppStore } from "../../../stores/webtoapp.store";
import { WTAButton } from "../../shared/WTAButton/WTAButton";

type Props = {
	page: WTAPage;
};

export const WTAPlanSummary: FC<Props> = ({ page }) => {

	useStyles(s);
	const [opacities, setOpacities] = useState<number[]>([0, 0, 0, 0]);
	const { enrolledLearningExperiences } = useContainerData('wta', WebToAppStore, ['enrolledLearningExperiences']);
	const { footer, progressBar, getPlanSummaryExperiences, toNextPage } = Container.take('wta', WebToAppService);

	useEffect(() => {
		footer.hide();
		progressBar.hide();
		getPlanSummaryExperiences();
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			setOpacities(prevOpacities => {
				const newOpacities = [...prevOpacities];
				const nextIndex = newOpacities.indexOf(0);
				if (nextIndex !== -1) {
					newOpacities[nextIndex] = 1;
					return newOpacities;
				} else {
					clearInterval(interval);
					return prevOpacities;
				}
			});
		}, 1000);
	}, [enrolledLearningExperiences]);

	return <Padding className="wta-plan-summary">
		<p className="wta-plan-summary-title">Your <span>personal learning plan</span> is ready!</p>
		<p className="wta-plan-summary-description">Based on your answers, we crafted a self-growth plan</p>
		<Image
			src="https://blob.sololearn.com/web-assets/undraw/roadtoknowledge.svg"
			style={{
				height: '180px',
				marginBottom: '42px'
			}}
		/>
		<p className="wta-plan-summary-courses-title" style={{ opacity: opacities[0] }}>
			What will you learn?
		</p>
		<div className="wta-plan-summary-courses">
			{enrolledLearningExperiences?.slice(0, 4).map((le, i) => {
				return <div className="wta-plan-summary-course" style={{ opacity: opacities[i] }}>
					<div className="wta-plan-summary-course-week">
						<span className="wta-plan-summary-course-week-label">week</span>
						<span className="wta-plan-summary-course-week-value">{i + 1}</span>
					</div>
					<div className="wta-plan-summary-course-details">
						<img className="wta-plan-summary-course-icon" src={le.uiConfigurations.iconUrl} />
						<div className="wta-plan-summary-course-titles">
							<span className="wta-plan-summary-course-name">{le.name}</span>
							<span className="wta-plan-summary-course-description">{le.description.slice(0, 52)}...</span>
						</div>
					</div>
				</div>;
			})}
			<div className="wta-plan-summary-overlay"></div>
		</div>
		<WTAButton className="wta-plan-summary-cta" onClick={toNextPage}>Get started</WTAButton>
	</Padding>;
};
