import React, { FC, useEffect, useState } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from "./WTAPlanBuildingPage.scss";
import { WTAPage, WTAPlanBuildingStep } from "../../../types/webtoapp.types";
import { Container } from "../../../../../symphony";
import { WebToAppService } from "../../../services/webtoapp.service";
import { Padding } from "../../shared/Padding/Padding";
import { Heading } from "../../pageComponents/Heading/Heading";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { WebToAppStore } from "../../../stores/webtoapp.store";
import FiveStarsIcon from "../../../../../staticPages/public/SlAILiteracy/components/Reviews/icons/FiveStarsIcon";

type Props = {
	page: WTAPage;
};

export const WTAPlanBuildingPage: FC<Props> = ({ page }) => {
	useStyles(s);

	const [currentStep, setCurrentStep] = useState<WTAPlanBuildingStep>(null);

	const { planBuildingSteps } = useContainerData('wta', WebToAppStore, ['planBuildingSteps']);
	const { footer, progressBar, planBuildingPageHandler, planBuildingResume } = Container.take('wta', WebToAppService);

	useEffect(() => {
		footer.disable();
		progressBar.hide();
		planBuildingPageHandler();
	}, []);

	useEffect(() => {
		setCurrentStep(planBuildingSteps.find(s => s.status !== 'completed') || null);
	}, [planBuildingSteps]);

	return <Padding className="wta-plan-building">
		<div className="wta-plan-building-heading">
			<Heading level={2} locKey="We are crafting your" style={{ fontSize: '28px' }} />
			<Heading level={2} locKey="learning experience..." style={{ fontSize: '26px', color: '#6b63ff', fontWeight: 600 }} />
		</div>
		<div className="wta-plan-building-steps">
			{planBuildingSteps.map(step => {
				if (step.status === 'notStarted') return null;
				return <div className="wta-plan-building-step" key={step.title}>
					<span className="wta-plan-building-step-title">
						{step.title}
					</span>
					<div className={`wta-plan-building-checkmark ${step.status === 'completed' ? 'completed' : ''}`}>
						<div className={`wta-plan-building-check ${step.status === 'completed' ? 'completed' : ''}`}></div>
					</div>
					{step.status !== 'completed' && <div className="wta-plan-building-progress">
						<div
							className="wta-plan-building-progress-fill"
							style={{ width: `${step.progress}%` }}
						></div>
					</div>}
				</div>;
			})}
		</div>
		{currentStep && <div className="wta-plan-building-footer">
			<span className="wta-plan-building-footer-title">
				Enjoyed by 30M+ people
			</span>
			<div className="wta-plan-building-review">
				<div className="wta-plan-building-review-heading">
					<div className="wta-plan-building-stars"><FiveStarsIcon /></div>
					<span>by <strong>{currentStep.reviewer}</strong></span>
				</div>
				<span className="wta-plan-building-review-text">{currentStep.review}</span>
			</div>
		</div>}
		{currentStep && currentStep.status === 'interrupted' ? <div className="wta-plan-building-alert">
			<div className="wta-plan-building-alert-container">
				<p className="wta-plan-building-alert-caption">To move forward, specify</p>
				<p className="wta-plan-building-alert-question">{currentStep.question}</p>
				<div className="wta-plan-building-alert-buttons">
					<button className="wta-plan-building-alert-button" onClick={() => planBuildingResume('no')}>No</button>
					<button className="wta-plan-building-alert-button" onClick={() => planBuildingResume('yes')}>Yes</button>
				</div>
			</div>
		</div> : null}
	</Padding>;
};