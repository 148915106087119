import { BehaviorSubject, catchError, combineLatest, concatMap, EMPTY, filter, firstValueFrom, Observable, tap } from 'rxjs';
import { Action } from "history";
import { AuthService, Container, CourseTrack, history, Service, StorageService, UserTrackService } from "../../../symphony";
import { WebToAppStore } from "../stores/webtoapp.store";
import { WTAAIorHumanAnswers, WTAAliasMapping, WTACheckpointState, WTAComponent, WTAComponentType, WTAJourney, WTAPage, WTAPageOption, WTAPageType, WTAQuizQuestion } from "../types/webtoapp.types";
import { SlLoginContext } from "../../../login/private/login-context";
import { SlLoginApiService } from "../../../login/private/services/sl-login-api.service";
import { DynamicOnboardingApi, IOnboardingAnswerPayload } from '../../../api/public/dynamic-onboarding-api';
import { LearnEngineApi, LocationType } from '../../../api/public/learn-engine/learn-engine-api';
import { ProfileApi } from '../../../api/public/profile-api';
import { PaywallsService } from '../../../_paywalls/private/services/paywalls.service';
import { Touchpoint } from '../../../api/public/subscription-api';
import { WebToAppTrackingSerivce } from './webtoapp.tracking.service';
import { appleConfigs, appleScriptConfigs } from '../../../login/private/containers/SocialLogin/configs';
import { WTADefaultJourney, WTAInitializationPage, WTAPlanBuildingSteps } from '../wta.constants';
import { getExperimentWithExposing, prefetchExperimentData } from '../../../shared/public/experimentsHandler/experimentsHandler';

@Service()
export class WebToAppService {
	private store = Container.take('wta', WebToAppStore);
	private initialParams: Record<string, string> = null;
	private loginApi = Container.take(SlLoginContext, SlLoginApiService);
	private authService = Container.take('global', AuthService);
	private onboardingApi = Container.take('global', DynamicOnboardingApi);
	private learnEngineApi = Container.take('global', LearnEngineApi);
	private profileApi = Container.take('global', ProfileApi);
	private paywallService = Container.take('paywalls', PaywallsService);
	private userTrackService = Container.take('global', UserTrackService);
	private tracking = Container.take('wta', WebToAppTrackingSerivce);
	private storageService = Container.take('global', StorageService);
	private userSelection = new BehaviorSubject<IOnboardingAnswerPayload[]>(null);
	private navigationSelection: string = '';
	private existingFlowId: string = '';

	constructor() {
		this.subscribeToBrowserBack();
		this.subscribeToUserSelection();
	}

	//#region PUBLIC API
	public initFlow = async (queryString: string) => {
		this.initialParams = Object.fromEntries(new URLSearchParams(queryString).entries());
		this.tracking.trackFlowOpen(this.initialParams);
		this.navigation.init();
		this.store.userEmail.next(this.storageService.load('wtaEmail', 'local') || null);
		if (this.initialParams['landingPageAlias'] === 'w2a-v2-work-from-anywhere-local') {
			!this.authService.isLoggedIn() && this.createTempUser().then(() => {
				this.userTrackService.setUserTrack(CourseTrack.AI);
				this.getJourney().then(() => {
					this.store.flowInitialized.next(true);
				});
			});
			this.setFlowData(WTADefaultJourney);
			this.getJourney().then(() => {
				this.store.flowInitialized.next(true);
			});
		} else {
			this.store.page.next(WTAInitializationPage);
			!this.authService.isLoggedIn() && await this.createTempUser();
			this.getExperiments();
			this.userTrackService.setUserTrack(CourseTrack.AI);
			this.setFlowData(await this.getJourney());
			this.store.flowInitialized.next(true);
		}
	};

	public toNextPage = () => {
		if (!this.store.flowInitialized.value) {
			this.store.journeyLoading.next(true);
			const interval = setInterval(() => {
				if (this.store.flowInitialized.value) {
					this.store.journeyLoading.next(false);
					this.toNextPage();
					clearInterval(interval);
				}
			}, 100);
		} else {
			this.store.journeyLoading.next(false);
			this.trackPageComplete();
			this.saveUserAnswers();
			this.manageEnrollments();
			this.navigation.set();
			this.definePage(false);
		}
	};
	//#endregion

	//#region INTERNAL
	private subscribeToBrowserBack = () => {
		history.listen(event => {
			if (event.action === Action.Pop) {
				this.navigation.back();
				this.definePage(true);
			}
		});
	};

	private subscribeToUserSelection = () => {
		this.userSelection.pipe(
			filter(payload => !!payload),
			concatMap(payload => this.onboardingApi.postUserAnswers(payload).pipe(
				catchError(err => EMPTY)
			))
		).subscribe();
	};

	private navigation = {
		init: () => {
			this.navigationSelection = this.storageService.load('wtaNavigation', 'local') || '';
			this.existingFlowId = this.storageService.load('wtaExistingFlow', 'local') || '';
		},
		reset: () => {
			this.navigationSelection = '';
			this.storageService.remove('wtaNavigation', 'local');
			this.storageService.remove('wtaExistingFlow', 'local');
		},
		set: () => {
			const { flowId } = this.store.flow.value;
			const { id: pageId, type } = this.store.page.value;
			const selectedOptions = this.store.selectedOptions.value[pageId];
			const navigation = selectedOptions ? selectedOptions[0].navigation : 0;
			this.navigationSelection = [
				this.navigationSelection,
				this.navigationSelection ? '|' : '',
				`${pageId}_${navigation}`
			].join('');
			this.storageService.save('wtaNavigation', this.navigationSelection, 'local');
			this.storageService.save('wtaExistingFlow', flowId.toString(), 'local');
			this.existingFlowId = flowId.toString();
		},
		back: () => {
			const parts = this.navigationSelection.split('|');
			parts.pop();
			this.navigationSelection = parts.join('|');
			this.storageService.save('wtaNavigation', this.navigationSelection, 'local');
		}
	};

	private setFlowData = (journey: WTAJourney) => {
		this.store.flow.next(journey.flow);
		this.store.journeyPages.next(journey.pages as WTAPage[]);
		this.store.checkpoints.next(journey.checkpoints && journey.checkpoints.length ? journey.checkpoints : null);
		this.prefetchAssets(journey.pages);
		this.tracking.trackFlowStart(this.initialParams, journey.flow.flowId);
		this.definePage(true);
	};

	private getExperiments = () => {
		const experiments = ['wta-single-choice-navigation'];
		experiments.forEach(async (expKey) => {
			await prefetchExperimentData(expKey);
			this.setExperimentData(expKey, getExperimentWithExposing(expKey).payload);
		});
	};

	private createTempUser = async () => {
		await firstValueFrom(this.loginApi.w2aSignup());
	};

	private getJourney = async () => {
		try {
			return (await firstValueFrom(this.onboardingApi.getWTAJourney(
				this.initialParams['landingPageAlias']
			)));
		} catch (err) {
			return WTADefaultJourney;
		}
	};

	private definePage = (replaceHistory: boolean) => {
		const { value: pages } = this.store.journeyPages;
		const { startPageId, flowId, navigations } = this.store.flow.value;
		let pageId = startPageId;
		let progress = 1;
		if (this.navigationSelection && (this.existingFlowId !== flowId.toString())) {
			this.navigation.reset();
		}
		if (this.navigationSelection) {
			const userSelections = this.navigationSelection.split('|').map(item => {
				const [pageId, value] = item.split('_').map(Number);
				return { pageId, value };
			});
			let nextPageId;
			let progressValue;
			userSelections.forEach(userSelection => {
				const navigation = navigations.find(n => n.pageId === userSelection.pageId && n.value === userSelection.value);
				nextPageId = navigation.nextPageId;
				progressValue = navigation.progress;
			});
			pageId = nextPageId;
			progress = progressValue;
		}

		const page = pages.find(p => p.id === pageId);
		this.tracking.trackPageImpression(this.initialParams, flowId, page.id);
		if (page.type === WTAPageType.emailUpdate) {
			this.tracking.trackEmailPageReached();
		}
		const historyParams = {
			pathname: window.location.pathname,
			search: new URLSearchParams({ ...this.initialParams, onbpage: page.id.toString() }).toString()
		};
		replaceHistory ? history.replace(historyParams) : history.push(historyParams);
		this.store.page.next(page);
		this.store.progressValue.next(progress);
		this.updateCheckpointStates();
	};

	private updateCheckpointStates = () => {
		const checkpoints = this.store.checkpoints.value;
		const page = this.store.page.value;
		if (!checkpoints) {
			return;
		} else if (!page.checkpointInfo) {
			this.store.checkpointsCompleted.next(true);
		} else {
			this.store.checkpointsCompleted.next(false);
			const inProgressCheckpoint = checkpoints.find(c => c.id === page.checkpointInfo.checkPointId);
			const states: WTACheckpointState[] = checkpoints.map(c => {
				let completedSteps: number;
				if (c.order === inProgressCheckpoint.order) {
					completedSteps = page.checkpointInfo.position;
				} else if (c.order > inProgressCheckpoint.order) {
					completedSteps = 0;
				} else if (c.order < inProgressCheckpoint.order) {
					completedSteps = c.pageCount;
				}
				return { ...c, completedSteps };
			});
			this.store.checkpointStates.next(states);
		}
	};

	private prefetchAssets = (pages: WTAPage[]) => {
		const getComponentAssetUrls = (component: WTAComponent): string[] => {
			switch (component.type) {
				case WTAComponentType.image:
					return [component.data];
				case WTAComponentType.likeDislike:
					return component.questions.map(q => q.imageUrl);
				default:
					return [];
			}
		};
		const assetURLs: string[] = [];
		pages.forEach(page => {
			page.pageOptions?.forEach(option => {
				if (option.iconUrl) {
					assetURLs.push(option.iconUrl);
				}
			});
			page.content?.forEach(c => {
				assetURLs.push(...getComponentAssetUrls(c));
			});
		});
		assetURLs.forEach(url => {
			const img = new Image();
			img.src = url;
			img.style.display = 'none';
			img.onload = () => { img.remove(); };
			img.onerror = () => { img.remove(); };
			document.body.appendChild(img);
		});
	};

	private trackPageComplete = () => {
		const { flowId, startPageId } = this.store.flow.value;
		const { id: pageId, type } = this.store.page.value;

		const selectedOptions = this.store.selectedOptions.value[pageId];
		const answerIds: number[] = selectedOptions ? selectedOptions.map(o => o.answerTypeId) : [];

		this.tracking.trackPageComplete({
			queryParams: this.initialParams,
			flowId,
			pageId,
			answerTypeIds: answerIds,
			startPageId
		});
	};

	private saveUserAnswers = () => {
		const { id: pageId } = this.store.page.value;
		const selectedOptions = this.store.selectedOptions.value[pageId];
		selectedOptions && this.userSelection.next(selectedOptions.map(o => ({
			typeId: o.questionTypeId,
			answerId: o.answerTypeId
		})));
	};

	private enrollToCourses = (aliases: string[]) => {
		const combined: Observable<any>[] = aliases.map(alias => {
			return this.learnEngineApi.enrollCourse({
				alias, locationTypeId: LocationType.Onboarding
			}).pipe(
				tap(() => { this.addToEnrolledCourses(alias); }),
				catchError(e => e)
			);
		});
		combineLatest(combined).subscribe();
	};

	private addToEnrolledCourses = (alias: string) => {
		const aliasesInLocalStorage = this.storageService.load('wtaEnrolledCourses', 'local') as string[];
		if (aliasesInLocalStorage) {
			this.storageService.save('wtaEnrolledCourses', [...aliasesInLocalStorage, alias], 'local');
		} else {
			this.storageService.save('wtaEnrolledCourses', [alias], 'local');
		}
	};

	private manageEnrollments = () => {
		const aliases = this.store.coursesToEnroll.value;
		if (aliases && aliases.length) {
			const uniqueAliases = Array.from(new Set(aliases));
			this.enrollToCourses(uniqueAliases);
			this.store.coursesToEnroll.next(null);
		}
	};

	private setExperimentData = (key: string, payload: Record<string, any>) => {
		this.store.experimentsData.next({
			...this.store.experimentsData.value,
			[key]: payload
		});
	};

	private saveSelectedOptions = (selectedOptions: WTAPageOption[]) => {
		const storeSelectedOptions = this.store.selectedOptions.value;
		this.store.selectedOptions.next({
			...storeSelectedOptions,
			[this.store.page.value.id]: selectedOptions
		});
	};
	//#endregion

	//#region FOOTER AND PROGRESS BAR
	private setFooterState = (state: 'disabled' | 'loading' | 'enabled' | 'hidden' | 'getStarted' | 'aiorhuman') => {
		this.store.footer.next(state);
	};

	public footer = {
		hide: () => this.setFooterState('hidden'),
		disable: () => this.setFooterState('disabled'),
		enable: () => this.setFooterState('enabled'),
		load: () => this.setFooterState('loading'),
		getStarted: () => this.setFooterState('getStarted'),
		aiOrHuman: () => this.setFooterState('aiorhuman'),
		style: (styles: React.CSSProperties) => this.store.footerButtonStyles.next(styles)
	};

	private setProgressBarState = (state: 'hidden' | 'shown') => {
		this.store.progressBar.next(state);
	};

	public progressBar = {
		hide: () => this.setProgressBarState('hidden'),
		show: () => this.setProgressBarState('shown')
	};
	//#endregion

	//#region PAGE HANDLERS
	//#region TYPE = TOPICS
	public topicPageHandler = (selectedTopcis: WTAPageOption[]) => {
		this.saveSelectedOptions(selectedTopcis);
		this.store.selectedTopics.next(selectedTopcis);
		if (selectedTopcis.length) {
			this.footer.enable();
		} else {
			this.footer.disable();
		}
	};
	//#endregion

	//#region TYPE = SINGLECHOICE
	public singleChoicePageHandler = (option: WTAPageOption) => {
		this.saveSelectedOptions([option]);
		this.footer.enable();
		if (
			this.store.experimentsData.value &&
			this.store.experimentsData.value['wta-single-choice-navigation'] &&
			this.store.experimentsData.value['wta-single-choice-navigation']['withContinue'] === false
		) {
			this.toNextPage();
		}
	};
	//#endregion

	//#region TYPE = MULTICHOICE
	public multiChoicePageHandler = (selectedOptions: WTAPageOption[]) => {
		this.saveSelectedOptions(selectedOptions);
		if (selectedOptions && selectedOptions.length) {
			this.footer.enable();
		} else {
			this.footer.disable();
		}
	};

	public saveAliases = (selectedOptions: WTAPageOption[], mappings: WTAAliasMapping) => {
		this.saveSelectedOptions(selectedOptions);
		if (selectedOptions && selectedOptions.length) {
			let coursesToEnroll = [];
			selectedOptions.forEach(o => {
				coursesToEnroll = [...coursesToEnroll, ...mappings[o.answerTypeId]];
			});
			this.store.coursesToEnroll.next(coursesToEnroll);
			this.footer.enable();
		} else {
			this.store.coursesToEnroll.next(null);
			this.footer.disable();
		}
	};
	//#endregion

	//#region TYPE = LOADING 
	public loadingPageHandler = () => {
		setTimeout(() => {
			this.toNextPage();
		}, 4000);
	};
	//#endregion

	//#region TYPE = PLAN BUILDING & PLAN SUMMARY
	public planBuildingPageHandler = async () => {
		const steps = [...WTAPlanBuildingSteps];
		const currentStep = steps.find(s => s.status !== 'completed');
		if (currentStep) {
			currentStep.status = currentStep.status === 'interrupted' ? 'resumed' : 'started';
			currentStep.progress = currentStep.status === 'resumed' ? currentStep.progress : 5;
			this.store.planBuildingSteps.next([...steps]);

			const interval = setInterval(() => {
				if (currentStep.progress > 50 && currentStep.status === 'started') {
					currentStep.status = 'interrupted';
					this.store.planBuildingSteps.next([...steps]);
					clearInterval(interval);
				}

				if (currentStep.progress < 100) {
					currentStep.progress += Math.floor(Math.random() * 10) + 1;
					this.store.planBuildingSteps.next([...steps]);
				} else {
					currentStep.status = 'completed';
					clearInterval(interval);
					setTimeout(() => {
						this.planBuildingPageHandler();
					}, 500);
				}
			}, 100);
		} else {
			this.store.planBuildingSteps.next([...steps]);
			this.footer.enable();
		}
	};

	public planBuildingResume = (answer: 'yes' | 'no') => {
		this.planBuildingPageHandler();
	};

	public getPlanSummaryExperiences = async () => {
		const res = await firstValueFrom(this.learnEngineApi.getLearningExperiences(true));
		const enrolledAliases = this.storageService.load('wtaEnrolledCourses', 'local') as string[];
		this.store.enrolledLearningExperiences.next(res.filter(le => enrolledAliases.includes(le.alias)));
	};
	//#endregion

	//#region TYPE = EMAIL UPDATE || REGISTRATION || CONNECT ACCOUNT
	public emailUpdatePageHandler = async (email: string) => {
		this.store.emailUpdateError.next(null);
		const user = this.authService.getUser();
		try {
			await firstValueFrom(this.profileApi.updateInfo(
				this.authService.getUser().id,
				{
					email,
					name: user.name,
					countryCode: user.countryCode,
					bio: ''
				}
			));
			this.storageService.save('wtaEmail', email, 'local');
			this.tracking.trackEmailProvided();
			this.store.userEmail.next(email);
			this.toNextPage();
		} catch (err) {
			this.store.emailUpdateError.next(err);
		}
	};

	public completeRegistrationPageHandler = async (
		payload: {
			password?: string;
		}
	) => {
		const { password } = payload;
		await firstValueFrom(this.loginApi.setPassword(null, password));
		this.toNextPage();
	};

	public googleLoginHandler = async (res: any) => {
		this.store.socialAccountError.next(null);
		const { access_token: accessToken } = res.getAuthResponse();
		try {
			await firstValueFrom(this.loginApi.socialLogin({ accessToken, provider: 'google' }));
			this.toNextPage();
		} catch (ex) {
			this.store.socialAccountError.next(ex);
		}
	};

	public appleLoginHandler = async () => {
		this.store.socialAccountError.next(null);
		try {
			const tokens = await window.AppleID?.auth?.signIn();
			await firstValueFrom(this.loginApi.authorizeApple({
				provider: 'Apple',
				code: tokens.authorization.code,
				user: {
					firstName: tokens.user?.name?.firstName,
					lastName: tokens.user?.name?.lastName,
				}
			}));
			this.toNextPage();
		} catch (ex) {
			this.store.socialAccountError.next(ex);
		}
	};

	public appleLoginInit = () => {
		const script = document.createElement('script');
		script.src = appleScriptConfigs.src;
		script.type = appleScriptConfigs.type;
		script.onload = () => {
			window.AppleID?.auth?.init({ ...appleConfigs, redirectURI: Container.take('global', 'redirectURI') });
		};
		document.body.appendChild(script);
	};
	//#endregion

	//#region TYPE = PAYWALL
	public paywallPageHandler = () => {
		this.paywallService.openPaywall({
			touchpoint: Touchpoint.web2app,
			props: {}
		}, {
			onClose: (method: 'back' | 'x') => {
				if (method !== 'back') {
					this.toNextPage();
				}
			}
		});
	};
	//#endregion 

	//#region TYPE = AI OR HUMAN
	public aiOrHumanPageHandler = (answer: WTAAIorHumanAnswers) => {
		const { id: pageId } = this.store.page.value;
		this.store.aiOrHumanAnswers.next({
			...this.store.aiOrHumanAnswers.value,
			[pageId]: answer
		});
		this.toNextPage();
	};
	//#endregion

	//#region TYPE = QUIZ
	public defineQuizQuestions = (questions: WTAQuizQuestion[]): WTAQuizQuestion[] => {
		const selectedTopics = this.store.selectedTopics.value;
		if (!selectedTopics) {
			const shuffled = questions.sort(() => 0.5 - Math.random());
			return shuffled.splice(0, 5);
		}
		let filteredQuestions = questions.filter(question =>
			selectedTopics.some(topic => question.topicIds.includes(topic.answerTypeId))
		);
		const selectedQuestions: any[] = [];
		const topicsCovered = new Set<number>();
		selectedTopics.forEach(topic => {
			const topicQuestions = filteredQuestions.filter(question => question.topicIds.includes(topic.answerTypeId));
			if (topicQuestions.length > 0) {
				const selectedQuestion = topicQuestions[0];
				if (!topicsCovered.has(topic.answerTypeId)) {
					selectedQuestions.push(selectedQuestion);
					topicsCovered.add(topic.answerTypeId);
					filteredQuestions = filteredQuestions.filter(question => question.id !== selectedQuestion.id);
				}
			}
		});
		const remainingSlots = 5 - selectedQuestions.length;
		const randomRemainingQuestions = filteredQuestions.sort(() => 0.5 - Math.random()).slice(0, remainingSlots);
		return selectedQuestions.concat(randomRemainingQuestions).sort(() => 0.5 - Math.random());
	};

	public quizPageHandler = (question: WTAQuizQuestion, result: 'like' | 'dislike', isCompleted: boolean) => {
		this.tracking.trackQuizAnswer(
			this.initialParams,
			this.store.flow.value.flowId,
			this.store.page.value.id,
			question.id,
			result
		);
		if (result === 'like') {
			this.enrollToCourses(question.aliases);
		}
		if (isCompleted) {
			this.toNextPage();
		}
	};
	// #endregion

	//#region TYPE = AGESELECT
	public onAgeSelect = (option: WTAPageOption) => {
		this.saveSelectedOptions([option]);
		this.toNextPage();
	};
	//#endregion

	//#endregion
}
