import { BehaviorSubject, Subject } from "rxjs";
import { ILearningExperience, Service } from "../../../symphony";
import { WTAAIorHumanAnswers, WTACheckpoint, WTACheckpointState, WTAFlow, WTAPage, WTAPageOption, WTAPlanBuildingStep } from "../types/webtoapp.types";

@Service()
export class WebToAppStore {
	public progressValue = new BehaviorSubject<number>(0);
	public progressBar = new BehaviorSubject<'shown' | 'hidden'>('hidden');
	public page = new BehaviorSubject<WTAPage>(null);
	public journeyPages = new BehaviorSubject<WTAPage[]>([]);
	public flow = new BehaviorSubject<WTAFlow>(null);
	public footer = new BehaviorSubject<'disabled' | 'loading' | 'enabled' | 'hidden' | 'getStarted' | 'aiorhuman' | 'categories'>('hidden');
	public journeyLoading = new BehaviorSubject<boolean>(false);
	public experimentsData = new BehaviorSubject<{ [key: string]: Record<string, any>; }>({});
	public planBuildingSteps = new BehaviorSubject<WTAPlanBuildingStep[]>([]);
	public planBuildingResumeInterruption = new Subject<'yes' | 'no'>();
	public footerButtonStyles = new BehaviorSubject<React.CSSProperties>(null);
	public enrolledLearningExperiences = new BehaviorSubject<ILearningExperience[]>(null);
	public flowInitialized = new BehaviorSubject<boolean>(false);
	public checkpoints = new BehaviorSubject<WTACheckpoint[]>([]);
	public checkpointStates = new BehaviorSubject<WTACheckpointState[]>(null);
	public checkpointsCompleted = new BehaviorSubject<boolean>(false);

	//USER SELECTIONS
	public selectedOptions = new BehaviorSubject<{ [pageId: number]: WTAPageOption[]; }>({});
	public selectedTopics = new BehaviorSubject<WTAPageOption[]>(null);
	public aiOrHumanAnswers = new BehaviorSubject<{ [pageId: number]: WTAAIorHumanAnswers; }>({});
	public emailUpdateError = new BehaviorSubject<Error>(null);
	public socialAccountError = new BehaviorSubject<Error>(null);
	public userEmail = new BehaviorSubject<string>(null);
	public coursesToEnroll = new BehaviorSubject<string[]>(null);
}
