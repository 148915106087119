import React, { FC } from "react";
import { WTAPageOption, WTASingleChoiceChangeHandler } from "../../../types/webtoapp.types";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './AgeSelection.scss';
import { Padding } from "../../shared/Padding/Padding";

type Props = {
	options: WTAPageOption[];
	onSelect: WTASingleChoiceChangeHandler;
	style: React.CSSProperties;
};

export const AgeSelection: FC<Props> = ({ onSelect, style, options }) => {
	useStyles(s);

	return <Padding style={style} className="wta-age-selection">
		{options.map(o => (<div
			key={o.id}
			className="wta-age-selection-item"
			onClick={() => onSelect(o)}
		>
			<div className="wta-age-selection-avatar-background">
				<img className="wta-age-selection-avatar" src={o.iconUrl} alt="" />
			</div>
			<div className="wta-age-selection-label">
				<span>{o.label}</span>
				<span>➜</span>
			</div>
		</div>))}
	</Padding>;
};